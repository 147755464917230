import React from 'react'
import '@shopify/polaris/styles.css'
import {
  AppProvider,
  Card,
  Layout,
  Tabs,
  Page,
  Banner,
  Modal,
  TextContainer,
  Button
} from "@shopify/polaris";
import { withRouter } from 'dva/router';
import Header from '../../components/Header/index'
import { connect } from "dva";
import { injectIntl } from 'react-intl';
import ListFilters from "./listFilters";
import en from "@shopify/polaris/locales/en.json";
import zh from "@shopify/polaris/locales/zh-CN.json";

class Retargeting extends React.Component {
  state = {
    selected: 2,
    message: undefined,
    banStatus: undefined,
    ruleCue: true,
    modelKey: false
  };
  async componentDidUpdate() {
    this.props.getLanguage(this.props.language, this.props.lang)
    const { message, lastUrl, banStatus } = this.props;
    // 如果按下Send Tracking按钮就重新请求订单
    if (
      lastUrl === "/api/abandoned_checkouts/generate_invoice" &&
      banStatus !== this.state.banStatus
    ) {
      this.setState({ message, banStatus });
      this.props.dispatch({
        type: "retargeting/asynRefresh",
      });
      if (banStatus === "success") {
        const remove = () => {
          this.props.dispatch({
            type: "retargeting/error",
          });
          this.setState({ message: undefined, banStatus: undefined });
        };
        setTimeout(remove, 10000);
      }
    }
  }
  // 控制tabs切换
  handleTabChange = (selected) => {
    const { history, hamc } = this.props
    this.setState({ selected });
    switch (selected) {
      case 0:
        history.push(`/?${hamc}`)
        break;
      case 1:
        history.push(`/Orders?${hamc}`)
        break;
      default:
        break;
    }
  };

  render() {
    const { selected, ruleCue, modelKey } = this.state;
    const {
      intl,
      message,
      banStatus,
    } = this.props;

    let messages = {}
    messages['en'] = en;
    messages['zh'] = zh;

    let tabs = [
      {
        id: "dashboard",
        content: intl.formatMessage({ id: "list.dashboard" }),
      },
      {
        id: "all-orders",
        content: intl.formatMessage({ id: "list.all" }),
      },
      {
        id: "retargeting",
        content: intl.formatMessage({ id: "list.retargeting" }),
      },
    ];

    const handleChange = () => {
      this.setState({
        modelKey: false
      })
    }

    return (
      <AppProvider features={{newDesignLanguage: true}} i18n={this.props.language ? messages[this.props.language] : messages[this.props.lang]}>
        <Header>
          {message ? (
            <Banner
              title={message === "sendEmail" ? intl.formatMessage({ id: `cue.sendEmailSuccess` }) : message}
              status={banStatus}
              onDismiss={() => {
                this.props.dispatch({
                  type: "retargeting/error",
                });
              }}
            />
          ) : (
              []
            )}
        </Header>
        <div style={{ marginTop: "-50px" }}>
          <Page fullWidth >
            <Layout >
              <Layout.Section >
                <Card >
                  <Tabs
                    tabs={tabs}
                    selected={selected}
                    onSelect={this.handleTabChange}
                  >
                    {
                      ruleCue ?
                        <Banner status="warning" onDismiss={() => {
                          this.setState({
                            ruleCue: false
                          })
                        }}>
                          <div>
                            <span>
                              {intl.formatMessage({ id: `ruleText.textHeader` })}
                            </span>
                            {/* style={{ color: "#468af9", textDecoration: "none" }} */}
                            <Button
                              plain={true}
                              onClick={
                                () => {
                                  this.setState({
                                    modelKey: true
                                  })
                                }
                              }
                            >
                              {intl.formatMessage({ id: `ruleText.textBody` })}
                            </Button>
                            <span>
                              {intl.formatMessage({ id: `ruleText.textFooter` })}
                            </span>
                          </div>
                        </Banner>
                        :
                        []
                    }
                    <ListFilters />
                  </Tabs>
                </Card>
              </Layout.Section>
            </Layout>
          </Page>
          <Modal
            open={modelKey}
            onClose={handleChange}
            title={intl.formatMessage({ id: `ruleText.title` })}
            primaryAction={{
              content: `${intl.formatMessage({ id: `ruleText.close` })}`,
              onAction: handleChange,
            }}
          >
            <Modal.Section>
              <TextContainer>
                <p>{intl.formatMessage({ id: `ruleText.textOne` })}</p>
                <p >{intl.formatMessage({ id: `ruleText.textTwo` })}</p>
                {/* <p>{intl.formatMessage({ id: `ruleText.textThree` })}</p> */}
              </TextContainer>
            </Modal.Section>
          </Modal>
        </div>
      </AppProvider >
    )
  }
}

export default connect((state) => {
  return {
    ...state.retargeting,
    language: state.set.language,
    lang: state.initial.lang,
    hamc: state.initial.hamc
  }
})(injectIntl(withRouter(Retargeting)));