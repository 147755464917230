const zn_CN = {
  "page.title": "Sync Tracking For PayPal",
  // 绑定PayPal按钮
  "update.btn": "更新追踪权限",
  "update.title": "追踪API权限",
  "update.confirm": "确认",
  "update.close": "关闭",
  "update.card": "使用您的PayPal帐户授予追踪API权限",
  "update.text": "确保您允许在Shopify商店中使用PayPal帐户",
  "remind.btn": "更新追踪权限",
  "remind.text": "确保您允许在Shopify商店中使用PayPal帐户",
  'authentication.title': "请从Shopify进入APP",
  'settingMsg.text':"设置成功",

  //主页筛选器
  "list.all": "同步订单",
  "list.notyetshipped": "尚未发货",
  'list.retargeting': "再营销",
  'list.dashboard': "数据分析",
  "list.trackinginfo": "订单信息",
  'list.abandonedinfo': "弃单信息",
  "list.settings": "设置",
  "syncStatus.title": "同步状态",
  "syncStatus.nosync": "未同步",
  "syncStatus.synced": "已同步",
  "syncStatus.different": "异常状态",
  "syncStatus.clear": "清除",
  "trackingStatus.title": "物流状态",
  "trackingStatus.shipped": "已发货",
  "trackingStatus.onhold": "等待中",
  "trackingStatus.delivered": "已交付",
  "trackingStatus.cancelled": "取消订单",
  "trackingStatus.clear": "清除",
  "fulfillmentStatus.title": "订单发货状态",
  "fulfillmentStatus.fulfilled": "已全部发货",
  "fulfillmentStatus.unfulfille": "未发货",
  "fulfillmentStatus.partial": "部分发货",
  "fulfillmentStatus.restocked": "重新发货",
  'email.delivered': "已交付",
  'email.undelivered': "未交付",
  'recovered.recovered': "已挽回",
  'recovered.unrecovered': "未挽回",
  "startDate.title": "开始日期",
  "endDate.title": "结束日期",
  'emailStatus.title': "邮箱状态",
  'emailStatus.sent': "已发送",
  'emailStatus.unsent': "未发送",
  'recoeredStatus.title': "挽回状态",
  'recoeredStatus.recoered': "已挽回",
  'recoeredStatus.unrecoered': "未挽回",
  'sentNumbers.title': "发送数量",
  'deliveryDate.title': "邮件发送日期",
  'creationDate.title': "创建日期",

  'ruleText.textHeader': "App 仅展示满足",
  'ruleText.textBody': "这些条件",
  'ruleText.textFooter': "的弃单",
  'ruleText.textOne': "1.弃单必须包含用户的电子邮件信息;",
  'ruleText.textTwo': "2.弃单必须是填写了发货地址的结算订单;",
  // 'ruleText.textTwo': "2.弃单必须是PayPal处于登录状态;",
  // 'ruleText.textThree': "3.弃单必须是填写了发货地址的结算订单;",
  'ruleText.title': "App 仅展示满足这些条件的弃单",
  'ruleText.close': "关闭",

  //订单列表
  'list.num': "序号",
  'list.order': "订单编号",
  'list.transactionid': "交易编号",
  'list.trackingnumber': "快递单号",
  'list.email': "邮箱",
  'list.createdate': "创建日期",
  'list.trackingupdatedate': "物流变动",
  'list.trackingstatus': "物流状态",
  'list.syncstatus': "同步状态",
  'list.send': "设置追踪",
  'list.sendab': "发送邮件",
  'list.checkout': "弃单ID",
  'list.creationdate': "创建日期",
  'list.emails': "邮箱",
  'list.deliverydate': "邮件发送日期",
  'list.total': "总计",
  'list.quantity': "发送次数",
  'list.emailstatus': "邮箱状态",
  'list.recoverystatus': "挽回状态",
  'list.action': "操作",

  //标签信息
  'tags.syncstatus': "同步状态",
  'tags.trackingstatus': "物流状态",
  'tags.fulfillmentstatus': "订单发货状态",
  'tags.shipmentstatus': "发货状态",
  'tags.starting': "开始于",
  'tags.Ending': "结束于",
  'tags.emailstatus': "邮箱状态",
  'tags.recoveredstatus': "挽回状态",
  'tags.sentnumbers': "发送次数",
  'tags.delivered': "已交付",
  'tags.undelivered': "未交付",
  'tags.creationdate': "创建日期",
  'tags.deliverydate': "邮件发送日期",

  //排序信息
  'sort.datedesc': "降序",
  'sort.dateasc': "升序",
  'sort.trackingcompanydesc': "物流公司降序",
  'sort.trackingcompanyasc': "物流公司升序",
  'sort.email': "邮箱排序",
  'sort.dateDesc': "创建日期降序",
  'sort.sendDateDesc': "发送日期降序",
  'sort.dateAsc': "创建日期升序",
  'sort.sendDateAsc': "发送日期升序",
  // 设置
  'set.title': "设置",
  'set.save': "保存",
  'set.cancel': "取消",
  'set.retargeting': "设置自动",
  'set.autom': "自动发送贝宝",
  'set.sendafter': "发送后",
  'set.hours': "小时",
  'set.minutes': "分钟",
  'set.recommended': "（推荐）",
  'set.businessName': "店铺名称:",
  'set.logoUrl': "logo url:",
  'set.term': "退款政策:",
  'set.note': "账单留言:",
  'set.text': "logo 像素须小于250*90" ,
  'set.hint': "最多支持1000个字符",
  'set.error':"logo url 格式错误",
  'set.errorName':"商店名称必须至少包含1个字符。",
  'set.billcover':"发票标题:",
  'set.bill':"发票明细:",
  'set.eg':"示例",
  'set.noticeTitle':"Retargeting功能正式上线",
  'set.noticeText1':"1.顾客使用PayPal支付的遗弃订单，买家将在指定时间内收到一封来自PayPal发出的票据邮件",
  'set.noticeText2':"2.票据邮件中商家可对邮件名称，公司LOGO等自定义",
  'set.noticeText3':"3.商家可选择邮件发送间隔时间，我们推荐10分钟。",
  'set.noticeText4':"4.通过票据支付的订单将会出现在订单列表中。",

  //订单详情页
  'msg.title': "订单详情页",
  'msg.close': "关闭",
  'msg.order': "订单",
  'msg.orderid': "订单编号 : ",
  'msg.paypaltrans': "贝宝账号名 : ",
  'msg.email': "邮箱",
  'msg.amount': "数量 : ",
  'msg.createdate': "创建日期 : ",
  'msg.tracking': "物流订单 ",
  'msg.trackingnumber': "物流订单单号 :",
  'msg.trackingcompany': "物流公司名 : ",
  'msg.trackingupdatedate': "近期物流变动",
  'msg.trackingstatus': "物流状态",
  'msg.fulfillmentstatus': "履行状态 : ",
  'msg.lineitems': "订单商品",

  //单条订单
  'item.nosync': "未同步",
  'item.synced': "已同步",
  'item.different': "异常状态",
  'item.shipped': "已发货",
  'item.onhold': "未发货",
  'item.delivered': "已交付",
  'item.cancelled': "取消订单",
  'item.sent': "已发送",
  'item.scheduled': '准备中',
  'item.unsent': "未发送",
  'item.recoered': "已挽回",
  'item.unrecoered': "未挽回",
  'item.sentbtn': "发送",

  'chart.fail': "异常订单",
  'chart.paypal': "PayPal订单",
  'chart.synced': "同步订单",
  'chart.success': "成功订单",
  'chart.email': "邮箱",
  'chart.retargeting': "挽回订单",
  'chart.title': "跟踪分析",
  'chart.titletwo': "弃单分析",
  'chart.titles': "跟踪分析",
  'chart.titlef': "弃单分析",
  'chart.ordersbypaypal': "PayPal订单",
  'chart.syncorders': "同步订单",
  'chart.succcessfulorders': "成功订单",
  'chart.failedorders': "异常订单",
  'chart.sentemail': "已发送邮件",
  'chart.successfulsentemail': "成功发送邮件",
  'chart.recoveryorders': "挽回订单",
  'chart.recoveredsclles': "挽回金额",

  'authorizationSuccess.successful': "PayPal授权成功",
  'authorizationSuccess.closethispage': "此页面",
  'authorizationSuccess.seconds': "秒后关闭",
  'authorizationSuccess.closethepage': "关闭页面",

  'cue.sendOrderSuccess': '已经成功同步到PayPal',
  'cue.sendEmailSuccess': '正在发送票据邮件',
}

export default zn_CN;