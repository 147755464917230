import React from "react";
import { AppProvider, Layout, EmptyState } from "@shopify/polaris";

function ErrorBound() {
  return (
    <AppProvider>
      <div style={{ background: "rgba(255,255,255,1)", height: "530px" }}>
        <Layout>
          <Layout.Section>
            <EmptyState
              heading="程序发生错误，给您造成不便十分抱歉，我们将尽快修复。"
              image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
            >
            </EmptyState>
          </Layout.Section>
        </Layout>
      </div>
    </AppProvider>
  )
}

export default ErrorBound;
