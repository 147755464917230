import React, { useCallback, useState } from 'react'
import { createHashHistory } from 'history'
import ReactGA from 'react-ga';
import { Router, Route, Switch } from 'dva/router'
import * as Sentry from '@sentry/react';
import Orders from './pages/Orders'
import Retargeting from './pages/Retargeting'
import ErrorBound from './pages/errorBound'
import AuthorizationSuccess from './pages/authorizationSuccess'
import Dashboard from './pages/Dashboard'
import {IntlProvider} from 'react-intl';
import en_US from './locale/en_US';
import zh_CN from './locale/zh_CN';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:process.env.DSN
  });
}
const history = createHashHistory()
ReactGA.initialize(process.env.GA);
ReactGA.pageview(history.location.pathname);
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

function RouterConfig({ history }) {
  const [activeNum, setActiveNum] = useState("");
  const [active, setActive] = useState("en");
  const handleActiveChange = useCallback(
    (value) => setActive(value),
    []
  );
  const handleActiveNumChange = useCallback(
    (value) => setActiveNum(value),
    []
  );
  const getLanguage = (data, value) => {
    handleActiveNumChange(data)
    handleActiveChange(value)
  }
  let messages = {}
  messages['en'] = en_US;
  messages['zh'] = zh_CN;

  return (
    <Sentry.ErrorBoundary fallback={ErrorBound} showDialog>
      <IntlProvider messages={activeNum ? messages[activeNum] : messages[active]} locale="en" >
        <Router history={history}>
          <Switch>
            <Route exact path="/"><Dashboard getLanguage={getLanguage} /></Route>
            <Route exact path="/Orders"><Orders getLanguage={getLanguage} /></Route>
            <Route exact path="/Retargeting"><Retargeting getLanguage={getLanguage} /></Route>
            <Route exact path="/AuthorizationSuccess"><AuthorizationSuccess getLanguage={getLanguage} /></Route>
          </Switch>
        </Router>
      </IntlProvider>
    </Sentry.ErrorBoundary>
  )
}

export default RouterConfig
