import React from "react";
import { ResourceItem, Button } from "@shopify/polaris";
import { connect } from "dva";
import { injectIntl } from 'react-intl';

class ListItem extends React.Component {
  // 是焦点时加下划线
  isFocus = (event) => {
    switch (event.type) {
      case "mouseenter":
        event.target.style.textDecoration = "";
        break;
      case "mouseleave":
        event.target.style.textDecoration = "none";
        break;
      default:
        break;
    }
  };
  // 时间格式
  formatConversion = (date) => {
    const re1 = /(?<year>[0-9]{4})[-](?<month>[0-9]{2})[-](?<day>[0-9]{2})/;
    const r1 = re1.exec(date);
    if (r1) {
      date = new Date(
        r1.groups.year,
        r1.groups.month - 1,
        r1.groups.day
      ).toString();
    }
    const re2 = /([a-zA-Z]+)[ ](?<month>[a-zA-Z]+)[ ](?<day>[0-9]{2})[ ](?<year>[0-9]{4})/;
    const r2 = re2.exec(date);
    if (r2) {
      date = `${r2.groups.month} ${r2.groups.day},${r2.groups.year}`;
    }
    return date;
  };

  orderNum = (id) => {
    const { data, page } = this.props;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        return page * 10 + i + 1 - 10;
      }
    }
  };

  render() {
    const { item, intl, dispatch, shopUrl } = this.props;
    let {
      created_at,
      id,
      invoices_count,
      order_id,
      send_date_gmt,
      original_data,
      status
    } = item;
    send_date_gmt = this.formatConversion(send_date_gmt);
    created_at = this.formatConversion(created_at);

    const dateStatus = (data) => {
      if (!data) {
        return
      }
      let dateArr = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec']
      let arr = data.split(' ').reverse()
      let popNum = arr.pop()
      let carr = arr[0].split(',')
      let tmp = dateArr.indexOf(popNum)
      return `${tmp + 1}月${carr[0]}日(${carr[1]})`
    }

    const handleSentChange = () => {
      dispatch({
        type: "retargeting/addBatchTrackings",
        payload: { id: [id] },
      });
    }
    const handleSendStatus = () => {
      if (status === "sent" || status === "paid") {
        return (
          <div style={{ width: "80%", height: "24px", lineHeight: "24px", backgroundColor: "#acfc8b", textAlign: "center", borderRadius: "50px" }}>{intl.formatMessage({ id: "item.sent" })}</div>
        )
      } else if (status === "scheduled") {
        return (
          <div style={{ width: "80%", height: "24px", lineHeight: "24px", backgroundColor: "#acfc8b", textAlign: "center", borderRadius: "50px" }}>{intl.formatMessage({ id: "item.scheduled" })}</div>
        )
      } else {
        return (
          <div style={{ width: "80%", height: "24px", lineHeight: "24px", backgroundColor: "#fa898c", textAlign: "center", borderRadius: "50px" }}>{intl.formatMessage({ id: "item.unsent" })}</div>
        )
      }
    }

    return (
      <div>
        <ResourceItem
          id={id}
          verticalAlignment="center"
          accessibilityLabel={`View details for ${id}`}
        >
          <div style={{
            width: "100%",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-around",
            wordWrap: "break-word",
            fontSize: "14px",
            fontFamily: "SFProText-Regular,SFProText",
            fontWeight: "400",
          }}>
            <div
              style={{
                width: "15%",
                marginRight: "1.5%",
                textAlign: "center",
              }}
            >
              <a
                href={`https://${shopUrl}/admin/checkouts/${id}`}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  color: "#006fbb",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {id}
              </a>
            </div>
            <div style={{ width: "15%", marginRight: "0.5%", textAlign: "center" }}>
              {intl.formatMessage({ id: "list.createdate" }) === "Create Date" ? created_at : dateStatus(created_at)}
            </div>
            <div style={{ width: "18%", marginRight: "0.5%", textAlign: "center" }}>
              {original_data.email}
            </div>
            <div style={{ width: "18%", marginRight: "0.5%", textAlign: "center" }}>
              {intl.formatMessage({ id: "list.createdate" }) === "Create Date" ? send_date_gmt : dateStatus(send_date_gmt)}
            </div>
            <div style={{ width: "9%", marginRight: "0.5%", textAlign: "center" }}>
              {`${original_data.total_price} ${original_data.currency}`}
            </div>
            <div style={{ width: "9%", marginRight: "1.5%", textAlign: "center" }}>
              {invoices_count}
            </div>
            <div style={{ width: "14%", marginRight: "0.1%", textAlign: "center" }}>
              {handleSendStatus()}
            </div>
            <div style={{ width: "14%", marginRight: "-0.5%", textAlign: "center" }}>
              {order_id ? <div style={{ width: "80%", height: "24px", lineHeight: "24px", backgroundColor: "#fee88d", textAlign: "center", borderRadius: "50px" }}>{intl.formatMessage({ id: "item.recoered" })}</div> : <div style={{ width: "80%", height: "24px", backgroundColor: "#fbc38c", textAlign: "center", borderRadius: "50px", lineHeight: "24px" }}>{intl.formatMessage({ id: "item.unrecoered" })}</div>}
            </div>
            <div style={{ width: "10%", marginRight: "0.5%", textAlign: "center" }}>
              <Button primary onClick={handleSentChange} >{intl.formatMessage({ id: "item.sentbtn" })}</Button>
            </div>
          </div>
        </ResourceItem>
      </div >
    );
  }
}
export default connect((state) => {
  return {
    ...state.retargeting,
    shopUrl: state.initial.shopUrl
  };
})(injectIntl(ListItem));
