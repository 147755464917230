import React from "react";
import "@shopify/polaris/styles.css";
import {
  AppProvider,
  Card,
  Page,
  Layout,
  Tabs,
  // Modal,
  // TextContainer,
  Spinner,
} from "@shopify/polaris";
import { withRouter } from "dva/router";
import { connect } from "dva";
import App from "../../components/Header/index";
import en from "@shopify/polaris/locales/en.json";
import zh from "@shopify/polaris/locales/zh-CN.json";
import LineChart from "../../components/LineChart/index";
import { injectIntl } from "react-intl";
import Ordersbypaypal from "../../assets/images/ordersbypaypal.png";
import Synced from "../../assets/images/synced.png";
import Success from "../../assets/images/success.png";
import Different from "../../assets/images/different.png";
// import Sentemail from '../../assets/images/sentemail.png';
// import Successfulsentemail from '../../assets/images/successfulsentemail.png';
// import Recoveryorders from '../../assets/images/recoveryorders.png';
// import Recoveredsclles from '../../assets/images/recoveredsclles.png';
// import noticeImg from "../../assets/images/bills.svg"

class Dashboard extends React.Component {
  state = {
    selected: 0,
    orderData: [],
    emailData: [],
    retargetingData: [],
    failData: [],
    paypalData: [],
    successData: [],
    syncedData: [],
    previewStatus: {},
    noticeKey: false,
  };
  async componentDidMount() {
    const { token, dispatch } = this.props;
    if (window.location.search.includes("hmac")) {
      if (token === "") {
        const payload_ = window.location.search.replace("?", "");
        await dispatch({
          type: "initial/getToken",
          payload: payload_,
        });
      }
      await dispatch({
        type: "dashboard/queryDashboard",
      });
      this.handlechartData();
    }
    if (this.props.first_enter) {
      const { dispatch } = this.props;
      await this.setState({
        noticeKey: true,
      });
      dispatch({
        type: "initial/getFirst",
      });
    }
  }

  componentDidUpdate() {
    this.props.getLanguage(this.props.language, this.props.lang);
  }

  componentWillUnmount() {
    this.setState = () => false;
  }

  handlechartData = () => {
    const { order, retargeting, email, intl } = this.props;
    const fail = intl.formatMessage({ id: "chart.fail" });
    const paypal = intl.formatMessage({ id: "chart.paypal" });
    const success = intl.formatMessage({ id: "chart.success" });
    const synced = intl.formatMessage({ id: "chart.synced" });
    const emails = intl.formatMessage({ id: "chart.email" });
    const retargetings = intl.formatMessage({ id: "chart.retargeting" });
    this.setState({
      failData: this.getChartData(order.data, fail, "fail"),
      paypalData: this.getChartData(order.data, paypal, "paypal"),
      successData: this.getChartData(order.data, success, "success"),
      syncedData: this.getChartData(order.data, synced, "synced"),
      emailData: this.getChartData(email.data, emails),
      retargetingData: this.getChartData(retargeting.data, retargetings),
    });
  };

  getChartData = (data, tags, types) => {
    if (data) {
      const newdata = JSON.parse(JSON.stringify(data));
      const arr = newdata.map((element) => {
        if (types) {
          element.count = element[types];
        }
        element.tags = tags;
        return element;
      });
      return arr;
    }
  };

  handleTags = (imgUrl, num, text, backgroundColor, color) => {
    return (
      <div style={{ minWidth: "23%" }}>
        <div
          className="Polaris-Card"
          style={{ backgroundColor: `${backgroundColor}`, boxShadow: "none" }}
        >
          <div className="Polaris-Card__Header">
            <img src={imgUrl} alt="" width="35px" />
          </div>
          <div className="Polaris-Card__Section">
            <div
              style={{
                fontSize: "23px",
                fontWeight: "bold",
                marginTop: "-10px",
                marginBottom: "10px",
                color: `${color}`,
              }}
            >
              {num}
            </div>
            <p style={{ color: `${color}`, opacity: "60%" }}>{text}</p>
          </div>
        </div>
      </div>
    );
  };

  // 更变语言的值后刷新图标翻译
  handlelang = () => {
    this.handlechartData();
  };

  handleTabChange = (selected) => {
    const { history, hamc } = this.props;
    this.setState({ selected });
    switch (selected) {
      case 1:
        history.push(`/Orders?${hamc}`);
        break;
      case 2:
        history.push(`/Retargeting?${hamc}`);
        break;
      default:
        break;
    }
  };

  handleNoticeKey = () => {
    this.setState({
      noticeKey: false,
    });
  };

  render() {
    const {
      selected,
      failData,
      paypalData,
      successData,
      syncedData,
    } = this.state;
    // const { selected, failData, paypalData, successData, syncedData, emailData, retargetingData, noticeKey } = this.state;
    const { order, intl, loading } = this.props;
    // const { order, email, retargeting, intl,loading } = this.props
    let messages = {};
    messages["en"] = en;
    messages["zh"] = zh;
    let tabs = [
      {
        id: "dashboard",
        content: intl.formatMessage({ id: "list.dashboard" }),
      },
      {
        id: "all-orders",
        content: intl.formatMessage({ id: "list.all" }),
      },
      // {
      //   id: "retargeting",
      //   content: intl.formatMessage({ id: "list.retargeting" }),
      // },
    ];

    return (
      <AppProvider
        features={{ newDesignLanguage: true }}
        i18n={
          this.props.language
            ? messages[this.props.language]
            : messages[this.props.lang]
        }
      >
        <App handlelang={this.handlelang} />
        <div style={{ marginTop: "-50px" }}>
          <Page fullWidth>
            <Layout>
              <Layout.Section>
                <Card>
                  <Tabs
                    tabs={tabs}
                    selected={selected}
                    onSelect={this.handleTabChange}
                  >
                    <div>
                      <Page
                        title={intl.formatMessage({ id: "chart.title" })}
                        fullWidth
                      >
                        <Layout>
                          <Layout.Section>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                              }}
                            >
                              {this.handleTags(
                                Ordersbypaypal,
                                order.count,
                                intl.formatMessage({
                                  id: "chart.ordersbypaypal",
                                }),
                                "#E8F2FE",
                                "#2092FF"
                              )}
                              {this.handleTags(
                                Synced,
                                order.synced,
                                intl.formatMessage({ id: "chart.syncorders" }),
                                "#ECECFF",
                                "#475CD3"
                              )}
                              {this.handleTags(
                                Success,
                                order.success,
                                intl.formatMessage({
                                  id: "chart.succcessfulorders",
                                }),
                                "#E9F6F8",
                                "#3BC3C2"
                              )}
                              {this.handleTags(
                                Different,
                                order.different,
                                intl.formatMessage({
                                  id: "chart.failedorders",
                                }),
                                "#FFF8F3",
                                "#FFAB50"
                              )}
                            </div>
                          </Layout.Section>
                          <Layout.Section>
                            <Card
                              title={intl.formatMessage({ id: "chart.titles" })}
                              sectioned
                            >
                              <div
                                style={{
                                  height: "420px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {loading ? (
                                  <Spinner
                                    accessibilityLabel="Loading form field"
                                    hasFocusableParent={false}
                                  />
                                ) : (
                                  <LineChart
                                    data={[
                                      ...paypalData,
                                      ...syncedData,
                                      ...successData,
                                      ...failData,
                                    ]}
                                    type="date*count"
                                    color={[
                                      "tags",
                                      [
                                        "#2092FF",
                                        "#475CD3",
                                        "#3BC3C2",
                                        "#FFAB50",
                                      ],
                                    ]}
                                  />
                                )}
                              </div>
                            </Card>
                          </Layout.Section>
                        </Layout>
                      </Page>
                      {/* <Page title={intl.formatMessage({ id: "chart.titletwo" })} fullWidth>
                        <Layout>
                          <Layout.Section >
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                              {this.handleTags(Sentemail, email.count, intl.formatMessage({ id: "chart.sentemail" }), "#FFF8F3", "#FFAB50")}
                              {this.handleTags(Successfulsentemail, email.sent, intl.formatMessage({ id: "chart.successfulsentemail" }), "#E9F6F8", "#3BC3C2")}
                              {this.handleTags(Recoveryorders, retargeting.count, intl.formatMessage({ id: "chart.recoveryorders" }), "#ECECFF", "#475CD3")}
                              {this.handleTags(Recoveredsclles, retargeting.amount, intl.formatMessage({ id: "chart.recoveredsclles" }), "#E8F2FE", "#2092FF")}
                            </div>
                          </Layout.Section>
                          <Layout.Section>
                            <Card title={intl.formatMessage({ id: "chart.titlef" })} sectioned>
                              <div  style={{height:'420px',display:'flex',justifyContent:"center",alignItems:"center"}}>
                              {
                              loading?(
                                <Spinner
                                  accessibilityLabel="Loading form field"
                                  hasFocusableParent={false}
                                />
                              ):(
                                <LineChart data={[...emailData, ...retargetingData]} type="date*count" color={["tags", ["#FFAB50", "#2092FF"]]} />
                                )
                              }
                              </div>
                            </Card>
                          </Layout.Section>
                        </Layout>
                      </Page> */}
                    </div>
                  </Tabs>
                </Card>
              </Layout.Section>
              {/* <Modal
                open={noticeKey}
                onClose={() => { this.handleNoticeKey() }}
                title="   "
                primaryAction={{
                  content: `${intl.formatMessage({ id: `ruleText.close` })}`,
                  onAction: () => { this.handleNoticeKey() },
                }}
              >
                <Modal.Section>
                  <div style={{
                    display: "flex"
                  }}>
                    <img src={noticeImg} alt="" width="130px" height="119px"
                      style={{
                        paddingRight: "28px"
                      }}
                    ></img>
                    <TextContainer style={{
                      fontSize: '16px',
                      fontFamily: 'SFProText-Regular, SFProText',
                      fontW: '400',
                      color: '#637381',
                      lineHeight: '24px',
                    }}>
                      <p style={{
                        width: "429px",
                        height: '64px',
                        fontSize: '22px',
                        fontFamily: 'SFProText-Regular, SFProText',
                        fontWeight: 'bold',
                        color: '#212B36',
                        lineHeight: '32px'
                      }}>{intl.formatMessage({ id: `set.noticeTitle` })}</p>
                      <p >{intl.formatMessage({ id: `set.noticeText1` })}</p>
                      <p >{intl.formatMessage({ id: `set.noticeText2` })}</p>
                      <p >{intl.formatMessage({ id: `set.noticeText3` })}</p>
                      <p >{intl.formatMessage({ id: `set.noticeText4` })}</p>
                    </TextContainer>
                  </div>
                </Modal.Section>
              </Modal> */}
            </Layout>
          </Page>
        </div>
      </AppProvider>
    );
  }
}

export default connect((state) => {
  return {
    ...state.dashboard,
    ...state.initial,
    language: state.set.language,
    loading: state.loading.effects["dashboard/queryDashboard"],
  };
})(injectIntl(withRouter(Dashboard)));
