import { getToken } from "../services/api";
import { setToken } from "../utils/authority";

export default {
  namespace: "initial",

  state: {
    token: "",
    hamc: "",
    shopUrl: "",
    authorized: true,
    lang: "en",
    langItem: [],
    invoice_logo_url:null,
    invoice_term:null,
    invoice_note:null,
    shop_name:null,
    first_enter:false
  },

  effects: {
    //请求token并保存到modal
    *getToken({ payload }, { call, put }) {
      const shop = /&shop=(?<url>.+)&/.exec(payload);
      yield put({ type: "saveUrl", response: { shopUrl: shop.groups.url } });
      const response = yield call(getToken, payload);
      if (response.token.value) {
        yield setToken(response.token.value);
        yield put({ type: "saveToken", response, payload });
      }
      yield put({ type: "saveInitial", response });
    },
    *getFirst(_, { put }) {
      yield put({ type: "saveFirstEnter" });
    },
  },

  reducers: {
    saveToken(state, action) {
      return {
        ...state,
        token: action.response.token.value,
        hamc: action.payload
      };
    },
    saveInitial(state, action) {
      let arr = []
      for (var p in action.response.lang.options) {
        arr.push({ label: action.response.lang.options[p], value: p })
      }
      return {
        ...state,
        authorized: action.response.paypal_authorized.value,
        lang: action.response.lang.value,
        langItem: arr,
        invoice_logo_url:action.response.invoice_logo_url.value,
        invoice_term:action.response.invoice_term.value,
        invoice_note:action.response.invoice_note.value,
        shop_name:action.response.shop_name.value,
        first_enter:action.response.first_enter.value
      };
    },
    saveUrl(state, action) {
      window.$crisp.push(["set", "session:data", ["shopUrl", `${action.response.shopUrl}`]]);
      return { ...state, ...action.response };
    },
    saveFirstEnter(state) {
      return { 
        ...state, 
        first_enter:false
       };
    },
  },
};