import React, { useCallback, useState, useEffect } from "react";
import {
  Card,
  ChoiceList,
  DatePicker,
  RangeSlider,
  Filters,
  Pagination,
  ResourceList,
} from "@shopify/polaris";
import { connect } from "dva";
import { injectIntl } from 'react-intl';
import ListHead from "./listHead";
import ListItem from "./listItem";
import debounce from "lodash/debounce";

// 标签信息
function disambiguateLabel(key, value, intl) {
  switch (key) {
    case "emailStatus":
      return `${intl.formatMessage({ id: "tags.emailstatus" })} - ${value.toString().toUpperCase()}`;
    case "sentNumbers":
      return `${intl.formatMessage({ id: "tags.sentnumbers" })} ( ${value[0].toString().toUpperCase()} - ${value[1].toString().toUpperCase()} )`;
    case "recoveredStatus":
      return `${intl.formatMessage({ id: "tags.recoveredstatus" })} - ${value.toString().toUpperCase()}`;
    case "creationDate":
      return `${intl.formatMessage({ id: "tags.creationdate" })} ${value}`;
    case "deliveryDate":
      return `${intl.formatMessage({ id: "tags.deliverydate" })} ${value}`;
    default:
      return value;
  }
}
// 判断数组、字符串是否为空
function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return value === "" || value == null;
}
//删除对象内属性为空的属性
function removeProperty(object) {
  for (var prop in object) {
    if (object[prop] === "") {
      delete object[prop];
    }
  }
}
function ResourceListFilters(props) {
  const {
    data,
    links,
    meta,
    loading,
    dispatch,
    datas,
    fils,
    token,
    intl
  } = props;
  const date = new Date();
  var datas__ = datas;
  var fils__ = fils;
  // 过滤状态
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [perPage, setPerPage] = useState(10);
  const [queryValue, setQueryValue] = useState(
    datas__[`filter[q]`] === undefined ? "" : datas__[`filter[q]`]
  );
  const [queryBlur, setQueryBlur] = useState(true);
  const [emailStatus, setEmailStatus] = useState(
    fils__[2] !== undefined ? fils__[2] : []
  );
  const [recoveredStatus, setRecoveredStatus] = useState(
    fils__[3] !== undefined ? fils__[3] : []
  );
  const [sentNumbers, setSentNumbers] = useState(
    fils__[4] !== undefined ? fils__[4] : null
  );
  const [{ startMonth, startYear }, setStartDate] = useState({
    startMonth: new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    ).getMonth(),
    startYear: new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    ).getFullYear(),
  });
  const [{ endMonth, endYear }, setEndDate] = useState({
    endMonth: date.getMonth(),
    endYear: date.getFullYear(),
  });
  const [creationDate, setCreationDate] = useState(
    fils__[0] !== undefined
      ? fils__[0]
      : {
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ),
        end: date
      }
  );
  const [deliveryDate, setDeliveryDate] = useState(
    fils__[1] !== undefined
      ? fils__[1]
      : {
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        ),
        end: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        )
      }
  );
  // 排序、多选状态
  const [sortValue, setSortValue] = useState("-date");
  const [selectedItems, setSelectedItems] = useState([]);
  // 其他状态
  const [active, setActive] = useState(false);
  const [activeNum, setActiveNum] = useState(true);
  // const [item, setItem] = useState({ line_items: [{}] });
  // 单个过滤条件重置
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
  }, []);
  const handleEmailStatusRemove = useCallback(() => setEmailStatus([]), []);
  const handleSentNumbersRemove = useCallback(() => {
    setSentNumbers([0, 5])
    setActiveNum(false)
  }, []);
  const handleRecoveredStatusRemove = useCallback(
    () => setRecoveredStatus([]),
    []
  );
  const handleCreationDateRemove = useCallback(
    () =>
      setCreationDate({
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ),
        end: date,
      }),
    [date]
  );
  const handleDeliveryDateRemove = useCallback(
    () =>
      setDeliveryDate({
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        ),
        end: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        )
      }),
    [date]
  );
  // 过滤条件全部重置
  const handleFiltersClearAll = useCallback(() => {
    handleEmailStatusRemove();
    handleSentNumbersRemove();
    handleRecoveredStatusRemove();
    handleQueryValueRemove();
    handleCreationDateRemove();
    handleDeliveryDateRemove();
  }, [
    handleEmailStatusRemove,
    handleSentNumbersRemove,
    handleRecoveredStatusRemove,
    handleQueryValueRemove,
    handleCreationDateRemove,
    handleDeliveryDateRemove,
  ]);

  // 过滤条件状态改变
  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
  }, []);
  const handleEmailStatusChange = useCallback(
    (value) => setEmailStatus(value),
    []
  );
  const debounceQuery = useCallback(debounce(fn => fn(), 500), []);
  const handleSentNumbersChange = useCallback(
    (value) => {
      setActiveNum(true)
      setSentNumbers(value)
    },
    [],
  );
  const handleRecoveredStatusChange = useCallback(
    (value) => setRecoveredStatus(value),
    []
  );
  // 修改日期月份的回调
  const handleStartMonthChange = useCallback(
    (month, year) => setStartDate({ startMonth: month, startYear: year }),
    []
  );
  const handleEndMonthChange = useCallback(
    (month, year) => setEndDate({ endMonth: month, endYear: year }),
    []
  );
  const handleQueryBlurChange = useCallback(() => {
    setQueryBlur(!queryBlur);
  }, [queryBlur]);
  // 监听当光标聚集搜索框时
  const handleQueryOnKeyDown = useCallback(
    (event) => {
      const enterKeyPressed = event.keyCode === 13;
      if (enterKeyPressed) {
        setQueryBlur(!queryBlur);
      }
    },
    [queryBlur]
  );
  const handlePageChange = useCallback((key, page) => {
    setSelectedItems([]);
    switch (key) {
      case "prev":
        setPage(--page);
        break;
      case "next":
        setPage(++page);
        break;
      default:
        console.log("handlePageChange", key);
        break;
    }
  }, []);

  const handleTagChange = useCallback(
    (item) => {
      setActive(item);
    },
    []
  );
  // 过滤条件
  const filters = [
    {
      key: "creationDate",
      label: intl.formatMessage({ id: "creationDate.title" }),
      filter: (
        <DatePicker
          month={startMonth}
          year={startYear}
          onChange={setCreationDate}
          onMonthChange={handleStartMonthChange}
          selected={creationDate}
          disableDatesBefore={
            new Date(
              date.getFullYear(),
              date.getMonth() - 1,
              date.getDate() + 1
            )
          }
          disableDatesAfter={date}
          allowRange
        />
      ),
    },
    {
      key: "deliveryDate",
      label: intl.formatMessage({ id: "deliveryDate.title" }),
      filter: (
        <DatePicker
          month={endMonth}
          year={endYear}
          onChange={setDeliveryDate}
          onMonthChange={handleEndMonthChange}
          selected={deliveryDate}
          disableDatesBefore={
            new Date(
              date.getFullYear(),
              date.getMonth() - 1,
              date.getDate() + 1
            )
          }
          disableDatesAfter={date}
          allowRange
        />
      ),
    },
    {
      key: 'sentNumbers',
      label: intl.formatMessage({ id: "sentNumbers.title" }),
      filter: (
        <RangeSlider
          label="Sent Numbers"
          labelHidden
          value={sentNumbers || [0, 500]}
          output
          min={0}
          max={5}
          step={1}
          onChange={handleSentNumbersChange}
        />
      ),
    },
    {
      key: "emailStatus",
      label: intl.formatMessage({ id: "emailStatus.title" }),
      filter: (
        <ChoiceList
          title="Email Status"
          titleHidden
          choices={[
            { label: intl.formatMessage({ id: "emailStatus.sent" }), value: "true" },
            { label: intl.formatMessage({ id: "emailStatus.unsent" }), value: "false" },
          ]}
          selected={emailStatus || []}
          onChange={handleEmailStatusChange}
        />
      ),
      shortcut: true,
    },
    {
      key: "recoveredStatus",
      label: intl.formatMessage({ id: "recoeredStatus.title" }),
      filter: (
        <ChoiceList
          title="Recovered Status"
          titleHidden
          choices={[
            { label: intl.formatMessage({ id: "recoeredStatus.recoered" }), value: "true" },
            { label: intl.formatMessage({ id: "recoeredStatus.unrecoered" }), value: "false" },
          ]}
          selected={recoveredStatus || []}
          onChange={handleRecoveredStatusChange}
        />
      ),
      shortcut: true,
    },
  ];

  // 选中操作
  const promotedBulkActions = [
    {
      content: intl.formatMessage({ id: "list.sendab" }),
      onAction: () => {
        dispatch({
          type: "retargeting/addBatchTrackings",
          payload: { id: selectedItems },
        });
        setSelectedItems([]);
      },
    },
  ];

  // 排序信息
  const sortOptions = [
    { label: intl.formatMessage({ id: "sort.dateDesc" }), value: "-date" },
    { label: intl.formatMessage({ id: "sort.dateAsc" }), value: "date" },
    { label: intl.formatMessage({ id: "sort.sendDateDesc" }), value: "-send_date" },
    { label: intl.formatMessage({ id: "sort.sendDateAsc" }), value: "send_date" },
  ];

  // 过滤条件标签
  const appliedFilters = [];
  if (!isEmpty(emailStatus)) {
    const key = "emailStatus";
    let arr = []
    emailStatus.forEach(item => {
      switch (item) {
        case "true":
          arr.push(intl.formatMessage({ id: "email.delivered" }))
          break;
        case "false":
          arr.push(intl.formatMessage({ id: "email.undelivered" }))
          break;
        default:
          arr.push(intl.formatMessage({ id: "email.undelivered" }))
      }
    })
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, arr, intl),
      onRemove: handleEmailStatusRemove,
    });
  }
  if (!isEmpty(sentNumbers) && activeNum) {
    const key = 'sentNumbers';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sentNumbers, intl),
      onRemove: handleSentNumbersRemove,
    });
  }
  if (!isEmpty(recoveredStatus)) {
    const key = "recoveredStatus";
    let arr = []
    recoveredStatus.forEach(item => {
      switch (item) {
        case "true":
          arr.push(intl.formatMessage({ id: "recovered.recovered" }))
          break;
        case "false":
          arr.push(intl.formatMessage({ id: "recovered.unrecovered" }))
          break;
        default:
          arr.push(intl.formatMessage({ id: "recovered.unrecovered" }))
      }
    })
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, arr, intl),
      onRemove: handleRecoveredStatusRemove,
    });
  }
  if (!isEmpty(creationDate.start)) {
    const key = "creationDate";
    let day = ""
    if (intl.formatMessage({ id: "startDate.title" }) === "Start Date") {
      day = `${creationDate.start.toDateString()}-${creationDate.end.toDateString()}`
    } else {
      let dateArr = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec']
      let arr = creationDate.start.toDateString().split(' ').slice(1).reverse()
      let popNum = arr.pop()
      let tmp = dateArr.indexOf(popNum)
      let arrEnd = creationDate.end.toDateString().split(' ').slice(1).reverse()
      let popNumEnd = arrEnd.pop()
      let tmpEnd = dateArr.indexOf(popNumEnd)
      day = `${arr[0]}年${tmp + 1}月${arr[1]}日-${arrEnd[0]}年${tmpEnd + 1}月${arrEnd[1]}日`
    }

    appliedFilters.push({
      key,
      label: disambiguateLabel(key, day, intl),
      onRemove: handleCreationDateRemove,
    });
  }
  if (!isEmpty(deliveryDate.start) && active) {
    const key = "deliveryDate";
    let day = ""
    if (intl.formatMessage({ id: "startDate.title" }) === "Start Date") {
      day = `${deliveryDate.start.toDateString()}-${deliveryDate.end.toDateString()}`
    } else {
      let dateArr = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec']
      let arr = deliveryDate.start.toDateString().split(' ').slice(1).reverse()
      let popNum = arr.pop()
      let tmp = dateArr.indexOf(popNum)
      let arrEnd = deliveryDate.end.toDateString().split(' ').slice(1).reverse()
      let popNumEnd = arrEnd.pop()
      let tmpEnd = dateArr.indexOf(popNumEnd)
      day = `${arr[0]}年${tmp + 1}月${arr[1]}日-${arrEnd[0]}年${tmpEnd + 1}月${arrEnd[1]}日`
    }
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, day, intl),
      onRemove: handleDeliveryDateRemove,
    });

  }

  // 副操作
  // 初始化page
  useEffect(() => {
    setPage(1);
  }, [
    sortValue,
    queryBlur,
    emailStatus,
    sentNumbers,
    recoveredStatus,
    creationDate,
    deliveryDate,
  ]);
  // 请求orders数据
  useEffect(() => {
    let sentStartDate = []
    let sentEndDate = []
    const startDate = `${creationDate.start.getFullYear()}-${
      creationDate.start.getMonth() + 1
      }-${creationDate.start.getDate()}`;
    const endDate = `${creationDate.end.getFullYear()}-${
      creationDate.end.getMonth() + 1
      }-${creationDate.end.getDate()}`;
    var fil = [
      creationDate,
      deliveryDate,
      emailStatus,
      recoveredStatus,
      sentNumbers
    ];
    let payload = {
      page,
      per_page: perPage,
      "filter[start_date]": startDate,
      "filter[end_date]": endDate,
      sort: sortValue,
    };
    var datas_ = datas;
    //如果为初次进入则请求token
    var dataBool = JSON.stringify(datas_) === "{}";
    if (dataBool && window.location.search.includes("hmac")) {
      async function getInitialDatas() {
        if (token === "") {
          const payload_ = window.location.search.replace("?", "");
          // var originUrl=window.location.href.replace("/?"+payload, '');
          // window.history.pushState(null, null, originUrl);
          await dispatch({
            type: "initial/getToken",
            payload: payload_,
          });
        }else{
          await dispatch({
            type: "retargeting/query",
            payload,
          })
        }
      }
      getInitialDatas()
    } else {
      if (datas_.page !== page) {
        datas_ = { ...datas_, page: page };
      }
      if (datas_.sort !== sortValue) {
        datas_ = { ...datas_, sort: sortValue };
      }
      if (startDate !== datas_[`filter[start_date]`] || endDate !== datas_[`filter[end_date]`]) {
        datas_ = { ...datas_, "filter[start_date]": startDate, "filter[end_date]": endDate };
      }
      if (deliveryDate.start.getDate() !== deliveryDate.end.getDate()) {
        handleTagChange(true)
        sentStartDate = `${deliveryDate.start.getFullYear()}-${
          deliveryDate.start.getMonth() + 1
          }-${deliveryDate.start.getDate()}`;
        sentEndDate = `${deliveryDate.end.getFullYear()}-${
          deliveryDate.end.getMonth() + 1
          }-${deliveryDate.end.getDate()}`;
        if (sentStartDate !== datas_[`filter[send_start_date]`] || sentEndDate !== datas_[`filter[send_end_date]`]) {
          datas_ = { ...datas_, "filter[send_start_date]": sentStartDate, "filter[send_end_date]": sentEndDate };
        }
      } else {
        datas_ = { ...datas_, "filter[send_start_date]": "", "filter[send_end_date]": "" };
      }
      // 添加请求参数
      if (queryValue.length !== 0 && !datas_[`filter[q]`] !== queryValue) {
        datas_ = { ...datas_, "filter[q]": queryValue };
      }
      if (queryValue.length === 0) {
        datas_ = { ...datas_, "filter[q]": "" };
      }
      if (sentNumbers) {
        datas_ = {
          ...datas_,
          "filter[send_quantity_min]": `${sentNumbers[0]}`,
          "filter[send_quantity_max]": `${sentNumbers[1]}`,
        };
      }
      if (!sentNumbers) {
        datas_ = {
          ...datas_,
          "filter[sent_numbers]": "",
        };
      }
      if (recoveredStatus.length !== 0) {
        datas_ = {
          ...datas_,
          "filter[recovery_status]": `${recoveredStatus}`,
        };
      }
      if (recoveredStatus.length === 0) {
        datas_ = {
          ...datas_,
          "filter[recovery_status]": "",
        };
      }
      if (emailStatus.length !== 0) {
        datas_ = {
          ...datas_,
          "filter[email_status]": `${emailStatus}`,
        };
      }
      if (emailStatus.length === 0) {
        datas_ = {
          ...datas_,
          "filter[email_status]": "",
        };
      }
      removeProperty(datas_);
      async function getFilterDatas() {
        await dispatch({
          type: "retargeting/query",
          payload: datas_,
        });
        await dispatch({
          type: "retargeting/fil",
          payload: fil,
        });
      }
      debounceQuery(getFilterDatas)
    }
    // eslint-disable-next-line
  }, [
    page,
    sortValue,
    queryBlur,
    emailStatus,
    sentNumbers,
    recoveredStatus,
    creationDate,
    deliveryDate,
    datas,
    token,
  ]);

  return (
    <div>
      <Card>
        <ResourceList
          resourceName={{ singular: "abandonedinfo info", plural: intl.formatMessage({ id: "list.abandonedinfo" }) }}
          loading={loading}
          totalItemsCount={meta ? meta.total : 0}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          promotedBulkActions={promotedBulkActions}
          sortValue={sortValue}
          sortOptions={sortOptions}
          onSortChange={(sortValue) => {
            setSortValue(sortValue);
          }}
          filterControl={
            <div onKeyDown={handleQueryOnKeyDown}>
              <Filters
                queryValue={queryValue}
                filters={filters}
                appliedFilters={appliedFilters}
                onQueryChange={handleFiltersQueryChange}
                onQueryClear={handleQueryValueRemove}
                onClearAll={handleFiltersClearAll}
                onQueryBlur={handleQueryBlurChange}
              />
            </div>
          }
          items={data}

          renderItem={(item, id, index) => {
            switch (index) {
              case 0:
                return (
                  <div>
                    <ListHead />
                    <ListItem
                      item={item}
                    />
                  </div>
                );
              default:
                return (
                  <div>
                    <ListItem
                      item={item}
                    />
                  </div>
                );
            }
          }}
        />
        <div
          style={{
            textAlign: "center",
            padding: "20px",
          }}
        >
          <Pagination
            hasPrevious={links && links.prev !== null ? links.prev : false}
            onPrevious={() => handlePageChange("prev", page)}
            hasNext={links && links.next !== null ? links.next : false}
            onNext={() => handlePageChange("next", page)}
          />
        </div>
      </Card>
    </div>
  );
}
export default connect((state) => {
  return {
    ...state.retargeting,
    loading: state.loading.models.retargeting,
    datas: state.retargeting.datas,
    token: state.initial.token,
  };
})(injectIntl(ResourceListFilters));
