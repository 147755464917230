import React, { useCallback, useState, useEffect } from "react";
import {
  Card,
  ChoiceList,
  DatePicker,
  Filters,
  Pagination,
  ResourceList,
  Modal,
} from "@shopify/polaris";
import { connect } from "dva";
import { injectIntl } from 'react-intl';
import ListHead from "./listHead";
import ListItem from "./listItem";
import debounce from "lodash/debounce";

// 标签信息
function disambiguateLabel(key, value, intl) {
  switch (key) {
    case "syncStatus":
      return `${intl.formatMessage({ id: "tags.syncstatus" })} - ${value.toString().toUpperCase()}`;
    case "trackingStatus":
      return `${intl.formatMessage({ id: "tags.trackingstatus" })} - ${value.toString().toUpperCase()}`;
    case "fulfillmentStatus":
      return `${intl.formatMessage({ id: "tags.fulfillmentstatus" })} - ${value.toString().toUpperCase()}`;
    case "shipmentStatus":
      return `${intl.formatMessage({ id: "tags.shipmentstatus" })} - ${value.toString().toUpperCase()}`;
    case "startDate":
      return `${intl.formatMessage({ id: "tags.starting" })} ${value}`;
    case "endDate":
      return `${intl.formatMessage({ id: "tags.Ending" })} ${value}`;
    default:
      return value;
  }
}
// 判断数组、字符串是否为空
function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return value === "" || value == null;
}
//删除对象内属性为空的属性
function removeProperty(object) {
  for (var prop in object) {
    if (object[prop] === "") {
      delete object[prop];
    }
  }
}
function ResourceListFilters(props) {
  const {
    // selected,
    data,
    links,
    meta,
    loading,
    dispatch,
    datas,
    fils,
    shopUrl,
    token,
    intl
  } = props;
  const date = new Date();
  var datas__ = datas;
  var fils__ = fils;
  // 过滤状态
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [perPage, setPerPage] = useState(10);
  const [queryValue, setQueryValue] = useState(
    datas__[`filter[q]`] === undefined ? "" : datas__[`filter[q]`]
  );
  const [queryBlur, setQueryBlur] = useState(true);
  const [syncStatus, setSyncStatus] = useState(
    fils__[2] !== undefined ? fils__[2] : []
  );
  const [trackingStatus, setTrackingStatus] = useState([]);
  const [fulfillmentStatus, setFulfillmentStatus] = useState(
    fils__[3] !== undefined ? fils__[3] : []
  );
  const [{ startMonth, startYear }, setStartDate] = useState({
    startMonth: new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    ).getMonth(),
    startYear: new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    ).getFullYear(),
  });
  const [{ endMonth, endYear }, setEndDate] = useState({
    endMonth: date.getMonth(),
    endYear: date.getFullYear(),
  });
  const [startSelectedDates, setStartSelectedDates] = useState(
    fils__[0] !== undefined
      ? fils__[0]
      : {
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ),
        end: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ),
      }
  );
  const [endSelectedDates, setEndSelectedDates] = useState(
    fils__[1] !== undefined
      ? fils__[1]
      : {
        start: date,
        end: date,
      }
  );
  // 排序、多选状态
  const [sortValue, setSortValue] = useState("-date");
  const [selectedItems, setSelectedItems] = useState([]);
  // 其他状态
  const [active, setActive] = useState(false);
  const [item, setItem] = useState({ line_items: [{}] });
  // 单个过滤条件重置
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
  }, []);
  const handleSyncStatusRemove = useCallback(() => setSyncStatus([]), []);
  const handleTrackingStatusRemove = useCallback(
    () => setTrackingStatus([]),
    []
  );
  const handleFulfillmentStatusRemove = useCallback(
    () => setFulfillmentStatus([]),
    []
  );
  const handleStartSelectedDatesRemove = useCallback(
    () =>
      setStartSelectedDates({
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ),
        end: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7),
      }),
    [date]
  );
  const handleEndSelectedDatesRemove = useCallback(
    () =>
      setEndSelectedDates({
        start: date,
        end: date,
      }),
    [date]
  );
  // 过滤条件全部重置
  const handleFiltersClearAll = useCallback(() => {
    handleSyncStatusRemove();
    handleTrackingStatusRemove();
    handleFulfillmentStatusRemove();
    handleQueryValueRemove();
    handleStartSelectedDatesRemove();
    handleEndSelectedDatesRemove();
  }, [
    handleSyncStatusRemove,
    handleTrackingStatusRemove,
    handleFulfillmentStatusRemove,
    handleQueryValueRemove,
    handleStartSelectedDatesRemove,
    handleEndSelectedDatesRemove,
  ]);

  // 过滤条件状态改变
  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
  }, []);
  const handleSyncStatusChange = useCallback(
    (value) => setSyncStatus(value),
    []
  );
  const handleTrackingStatusChange = useCallback(
    (value) => setTrackingStatus(value),
    []
  );
  const handleShipmentStatusChange = useCallback(
    (value) => setFulfillmentStatus(value),
    []
  );
  // 修改日期月份的回调
  const handleStartMonthChange = useCallback(
    (month, year) => setStartDate({ startMonth: month, startYear: year }),
    []
  );
  const handleEndMonthChange = useCallback(
    (month, year) => setEndDate({ endMonth: month, endYear: year }),
    []
  );
  const handleQueryBlurChange = useCallback(() => {
    setQueryBlur(!queryBlur);
  }, [queryBlur]);
  // 监听当光标聚集搜索框时
  const handleQueryOnKeyDown = useCallback(
    (event) => {
      const enterKeyPressed = event.keyCode === 13;
      if (enterKeyPressed) {
        setQueryBlur(!queryBlur);
      }
    },
    [queryBlur]
  );
  const handlePageChange = useCallback((key, page) => {
    setSelectedItems([]);
    switch (key) {
      case "prev":
        setPage(--page);
        break;
      case "next":
        setPage(++page);
        break;
      default:
        console.log("handlePageChange", key);
        break;
    }
  }, []);
  // showMore
  const handleModalChange = useCallback(
    (item) => {
      setActive(!active);
      if (item.target !== undefined) {
        setItem({ line_items: [{}] });
      } else {
        setItem(item);
      }
    },
    [active]
  );
  //防抖
  const debounceQuery = useCallback(debounce(fn => fn(), 1000), []);
  // 过滤条件
  const filters = [
    // sync_status,可选值:NOT_SYNC、SYNC、SYNC_DIFF.
    {
      key: "syncStatus",
      label: intl.formatMessage({ id: "syncStatus.title" }),
      filter: (
        <ChoiceList
          title="Sync Status"
          titleHidden
          choices={[
            { label: intl.formatMessage({ id: "syncStatus.nosync" }), value: "NOT_SYNC" },
            { label: intl.formatMessage({ id: "syncStatus.synced" }), value: "SYNC" },
            { label: intl.formatMessage({ id: "syncStatus.different" }), value: "SYNC_DIFF" },
          ]}
          selected={syncStatus || []}
          onChange={handleSyncStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    // trackingStatus,可选值:SHIPPED、ON_HOLD、DELIVERED、CANCELLED
    {
      key: "trackingStatus",
      label: intl.formatMessage({ id: "trackingStatus.title" }),
      filter: (
        <ChoiceList
          title="Tracking Status"
          titleHidden
          choices={[
            { label: intl.formatMessage({ id: "trackingStatus.shipped" }), value: "SHIPPED" },
            { label: intl.formatMessage({ id: "trackingStatus.onhold" }), value: "ON_HOLD" },
            { label: intl.formatMessage({ id: "trackingStatus.delivered" }), value: "DELIVERED" },
            { label: intl.formatMessage({ id: "trackingStatus.cancelled" }), value: "CANCELLED" },
          ]}
          selected={trackingStatus || []}
          onChange={handleTrackingStatusChange}
          allowMultiple
        // disabled={selected !== 0}
        />
      ),
      shortcut: true,
      // disabled: selected !== 0,
    },
    // fulfillmentStatus,可选值:FULFILLED,NULL,PARTIAL,RESTOCKED
    {
      key: "fulfillmentStatus",
      label: intl.formatMessage({ id: "fulfillmentStatus.title" }),
      filter: (
        <ChoiceList
          title="Fulfillment Status"
          titleHidden
          choices={[
            { label: intl.formatMessage({ id: "fulfillmentStatus.fulfilled" }), value: "FULFILLED" },
            { label: intl.formatMessage({ id: "fulfillmentStatus.unfulfille" }), value: "UNFULFILLED" },
            { label: intl.formatMessage({ id: "fulfillmentStatus.partial" }), value: "PARTIAL" },
            { label: intl.formatMessage({ id: "fulfillmentStatus.restocked" }), value: "RESTOCKED" },
          ]}
          selected={fulfillmentStatus || []}
          onChange={handleShipmentStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    // startDate
    {
      key: "startDate",
      label: intl.formatMessage({ id: "startDate.title" }),
      filter: (
        <DatePicker
          month={startMonth}
          year={startYear}
          onChange={setStartSelectedDates}
          onMonthChange={handleStartMonthChange}
          selected={startSelectedDates}
          disableDatesBefore={
            new Date(
              date.getFullYear(),
              date.getMonth() - 1,
              date.getDate() + 1
            )
          }
          disableDatesAfter={date}
        />
      ),
    },
    // endDate
    {
      key: "endDate",
      label: intl.formatMessage({ id: "endDate.title" }),
      filter: (
        <DatePicker
          month={endMonth}
          year={endYear}
          onChange={setEndSelectedDates}
          onMonthChange={handleEndMonthChange}
          selected={endSelectedDates}
          disableDatesBefore={
            new Date(
              date.getFullYear(),
              date.getMonth() - 1,
              date.getDate() + 1
            )
          }
          disableDatesAfter={date}
        />
      ),
    },
  ];

  // 选中操作
  const promotedBulkActions = [
    {
      content: intl.formatMessage({ id: "list.send" }),
      onAction: () => {
        dispatch({
          type: "order/addBatchTrackings",
          payload: { order_ids: selectedItems },
        });
        setSelectedItems([]);
      },
    },
  ];

  // 排序信息
  const sortOptions = [
    { label: intl.formatMessage({ id: "sort.datedesc" }), value: "-date" },
    { label: intl.formatMessage({ id: "sort.dateasc" }), value: "date" },
    { label: intl.formatMessage({ id: "sort.trackingcompanydesc" }), value: "-tracking_company" },
    { label: intl.formatMessage({ id: "sort.trackingcompanyasc" }), value: "tracking_company" },
    // { label: intl.formatMessage({ id: "sort.email" }), value: "email" },
  ];

  // 过滤条件标签
  const appliedFilters = [];
  if (!isEmpty(syncStatus)) {
    const key = "syncStatus";
    let arr = []
    syncStatus.forEach(item => {
      switch (item) {
        case "NOT_SYNC":
          arr.push(intl.formatMessage({ id: "syncStatus.nosync" }))
          break;
        case "SYNC":
          arr.push(intl.formatMessage({ id: "syncStatus.synced" }))
          break;
        case "SYNC_DIFF":
          arr.push(intl.formatMessage({ id: "syncStatus.different" }))
          break;
        default:
          arr.push(intl.formatMessage({ id: "syncStatus.nosync" }))
      }
    })
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, arr, intl),
      onRemove: handleSyncStatusRemove,
    });
  }
  if (!isEmpty(trackingStatus)) {
    const key = "trackingStatus";
    let arr = []
    trackingStatus.forEach(item => {
      switch (item) {
        case "SHIPPED":
          arr.push(intl.formatMessage({ id: "trackingStatus.shipped" }))
          break;
        case "ON_HOLD":
          arr.push(intl.formatMessage({ id: "trackingStatus.onhold" }))
          break;
        case "DELIVERED":
          arr.push(intl.formatMessage({ id: "trackingStatus.delivered" }))
          break;
        case "CANCELLED":
          arr.push(intl.formatMessage({ id: "trackingStatus.cancelled" }))
          break;
        default:
          arr.push(intl.formatMessage({ id: "trackingStatus.shipped" }))
      }
    })
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, arr, intl),
      onRemove: handleTrackingStatusRemove,
    });
  }
  if (!isEmpty(fulfillmentStatus)) {
    const key = "fulfillmentStatus";
    let arr = []
    fulfillmentStatus.forEach(item => {
      switch (item) {
        case "FULFILLED":
          arr.push(intl.formatMessage({ id: "fulfillmentStatus.fulfilled" }))
          break;
        case "UNFULFILLED":
          arr.push(intl.formatMessage({ id: "fulfillmentStatus.unfulfille" }))
          break;
        case "PARTIAL":
          arr.push(intl.formatMessage({ id: "fulfillmentStatus.partial" }))
          break;
        case "RESTOCKED":
          arr.push(intl.formatMessage({ id: "fulfillmentStatus.restocked" }))
          break;
        default:
          arr.push(intl.formatMessage({ id: "fulfillmentStatus.fulfilled" }))
      }
    })
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, arr, intl),
      onRemove: handleFulfillmentStatusRemove,
    });
  }
  if (!isEmpty(startSelectedDates.start)) {
    const key = "startDate";
    let day = ""
    if (intl.formatMessage({ id: "startDate.title" }) === "Start Date") {
      day = startSelectedDates.start?startSelectedDates.start.toDateString():""
    } else {
      let dateArr = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec']
      let arr = startSelectedDates.start.toDateString().split(' ').slice(1).reverse()
      let popNum = arr.pop()
      let tmp = dateArr.indexOf(popNum)
      day = `${arr[0]}年${tmp + 1}月${arr[1]}日`
    }

    appliedFilters.push({
      key,
      label: disambiguateLabel(key, day, intl),
      onRemove: handleStartSelectedDatesRemove,
    });
  }
  if (!isEmpty(endSelectedDates.start)) {
    const key = "endDate";
    let day = ""
    if (intl.formatMessage({ id: "startDate.title" }) === "Start Date") {
      day = endSelectedDates.start.toDateString()
    } else {
      let dateArr = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec']
      let arr = endSelectedDates.start.toDateString().split(' ').slice(1).reverse()
      let popNum = arr.pop()
      let tmp = dateArr.indexOf(popNum)
      day = `${arr[0]}年${tmp + 1}月${arr[1]}日`
    }
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, day, intl),
      onRemove: handleEndSelectedDatesRemove,
    });
  }

  // 副操作
  // 初始化page
  useEffect(() => {
    setPage(1);
  }, [
    sortValue,
    queryBlur,
    syncStatus,
    trackingStatus,
    fulfillmentStatus,
    startSelectedDates,
    endSelectedDates,
  ]);
  // 请求orders数据
  useEffect(() => {
    const startDate = `${startSelectedDates.start.getFullYear()}-${
      startSelectedDates.start.getMonth() + 1
      }-${startSelectedDates.start.getDate()}`;
    const endDate = `${endSelectedDates.start.getFullYear()}-${
      endSelectedDates.start.getMonth() + 1
      }-${endSelectedDates.start.getDate()}`;
    var fil = [
      startSelectedDates,
      endSelectedDates,
      syncStatus,
      fulfillmentStatus,
    ];
    let payload = {
      page,
      per_page: perPage,
      "filter[start_date]": startDate,
      "filter[end_date]": endDate,
      sort: sortValue,
    };
    var datas_ = datas;
    //如果为初次进入则请求token
    var dataBool = JSON.stringify(datas_) === "{}";
    if (dataBool && window.location.search.includes("hmac")) {
      async function getInitialDatas() {
        if (token === "") {
          const payload_ = window.location.search.replace("?", "");
          // var originUrl=window.location.href.replace("/?"+payload, '');
          // window.history.pushState(null, null, originUrl);
          await dispatch({
            type: "initial/getToken",
            payload: payload_,
          });
        }else{
          await dispatch({
            type: "order/query",
            payload,
          });
        }
      }
      getInitialDatas()
    } else {
      if (datas_.page !== page) {
        datas_ = { ...datas_, page: page };
      }
      if (datas_.sort !== sortValue) {
        datas_ = { ...datas_, sort: sortValue };
      }
      if (endDate !== datas_[`filter[start_date]`]) {
        datas_ = { ...datas_, "filter[start_date]": startDate };
      }
      if (startDate !== datas_[`filter[end_date]`]) {
        datas_ = { ...datas_, "filter[end_date]": endDate };
      }
      // 添加请求参数
      if (queryValue.length !== 0 && !datas_[`filter[q]`] !== queryValue) {
        datas_ = { ...datas_, "filter[q]": queryValue };
      }
      if (queryValue.length === 0) {
        datas_ = { ...datas_, "filter[q]": "" };
      }
      // switch (selected) {
      //   case 0:
      //     if (trackingStatus.length !== 0) {
      //       datas_ = {
      //         ...datas_,
      //         "filter[tracking_status]": `${trackingStatus}`,
      //       };
      //     }
      //     if (trackingStatus.length === 0) {
      //       datas_ = {
      //         ...datas_,
      //         "filter[tracking_status]": "",
      //       };
      //     }
      //     break;
      //   case 1:
      //     datas_ = { ...datas_, "filter[tracking_status]": "NOT_SENT" };
      //     break;
      //   default:
      //     console.warn(`找不到和索引为${selected}相匹配的tab`);
      //     break;
      // }
      if (trackingStatus.length !== 0) {
        datas_ = {
          ...datas_,
          "filter[tracking_status]": `${trackingStatus}`,
        };
      }
      if (trackingStatus.length === 0) {
        datas_ = {
          ...datas_,
          "filter[tracking_status]": "",
        };
      }
      if (fulfillmentStatus.length !== 0) {
        datas_ = {
          ...datas_,
          "filter[fulfillment_status]": `${fulfillmentStatus}`,
        };
      }
      if (fulfillmentStatus.length === 0) {
        datas_ = {
          ...datas_,
          "filter[fulfillment_status]": "",
        };
      }
      if (syncStatus.length !== 0) {
        datas_ = {
          ...datas_,
          "filter[sync_status]": `${syncStatus}`,
        };
      }
      if (syncStatus.length === 0) {
        datas_ = {
          ...datas_,
          "filter[sync_status]": "",
        };
      }
      removeProperty(datas_);
      async function getFilterDatas() {
        await dispatch({
          type: "order/query",
          payload: datas_,
        });
        await dispatch({
          type: "order/fil",
          payload: fil,
        });
      }
      debounceQuery(getFilterDatas)
    }
    // eslint-disable-next-line
  }, [
    page,
    sortValue,
    queryBlur,
    syncStatus,
    trackingStatus,
    fulfillmentStatus,
    startSelectedDates,
    endSelectedDates,
    datas,
    token,
  ]);
  var lineItem = item.line_items.map((lineItem, key) => (
    <div
      key={key}
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        fontSize: "12px",
        fontfamily: "SFProText-Bold,SFProText",
        color: "rgba(33,43,54,1)",
        ineHeight: "14px",
        minHeight: "",
      }}
    >
      <div style={{ width: "30%", margin: "0px 0px 10px 3%" }}>
        {lineItem.name}
      </div>
      <div style={{ width: "30%", margin: "0px 0px 10px 1.5%" }}>
        {lineItem.price} {item.currency} × {lineItem.quantity}
      </div>
      <div style={{ width: "30%", margin: "0px 0px 10px 1.5%" }}>
        {(lineItem.price * lineItem.quantity)&&(lineItem.price * lineItem.quantity).toFixed(2)} {item.currency}
      </div>
    </div>
  ));

  return (
    <div>
      <Card>
        <ResourceList
          resourceName={{ singular: "tracking info", plural: intl.formatMessage({ id: "list.trackinginfo" }) }}
          loading={loading}
          totalItemsCount={meta ? meta.total : 0}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          promotedBulkActions={promotedBulkActions}
          sortValue={sortValue}
          sortOptions={sortOptions}
          onSortChange={(sortValue) => {
            setSortValue(sortValue);
          }}
          filterControl={
            <div onKeyDown={handleQueryOnKeyDown}>
              <Filters
                queryValue={queryValue}
                filters={filters}
                appliedFilters={appliedFilters}
                onQueryChange={handleFiltersQueryChange}
                onQueryClear={handleQueryValueRemove}
                onClearAll={handleFiltersClearAll}
                onQueryBlur={handleQueryBlurChange}
              />
            </div>
          }
          items={data}
          renderItem={(item, id, index) => {
            switch (index) {
              case 0:
                return (
                  <div>
                    <ListHead />
                    <ListItem
                      item={item}
                      handleModalChange={handleModalChange}
                    />
                  </div>
                );
              default:
                return (
                  <div>
                    <ListItem
                      item={item}
                      handleModalChange={handleModalChange}
                    />
                  </div>
                );
            }
          }}
        />
        <div
          style={{
            textAlign: "center",
            padding: "20px",
          }}
        >
          <Pagination
            hasPrevious={links && links.prev !== null ? links.prev : false}
            onPrevious={() => handlePageChange("prev", page)}
            hasNext={links && links.next !== null ? links.next : false}
            onNext={() => handlePageChange("next", page)}
          />
        </div>
      </Card>
      <Modal
        open={active}
        onClose={handleModalChange}
        title={intl.formatMessage({ id: "msg.title" })}
        secondaryActions={[
          {
            content: intl.formatMessage({ id: "msg.close" }),
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              fontSize: "12px",
              fontfamily: "SFProText-Bold,SFProText",
              color: "rgba(33,43,54,1)",
              ineHeight: "14px",
              marginBottom: "10px",
              padding: "20px 0 10px",
              background: "rgba(245,245,245,1)",
              marginTop: "-10px",
            }}
          >
            <div
              style={{
                width: "100%",
                margin: "0 0 20px 3%",
                fontSize: "18px",
                fontFamily: "SFProText-Bold SFProText",
                fontWeight: "bold",
                color: "rgba(33,43,54,1)",
                lineHeight: "20px",
              }}
            >
              <p className="title" style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.order" })}</p>
            </div>

            <div style={{ width: "30%", margin: "0px 0px 10px 3%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.orderid" })}</p>
              <br />
              <a
                href={`https://${shopUrl}/admin/orders/${item.id}`}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  color: "#006fbb",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {item.id}
              </a>
            </div>
            <div style={{ width: "30%", margin: "0px 0px 10px 1.5%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.paypaltrans" })}</p>
              <br />
              {item.transaction_id}
            </div>
            <div style={{ width: "30%", margin: "0px 4% 10px 1.5%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.email" })}</p>
              <br />
              {item.email}
            </div>
            <div style={{ width: "30%", margin: "0px 0px 10px 3%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.amount" })}</p>
              <br />
              {item.amount}
            </div>
            <div style={{ width: "30%", margin: "0px 0px 10px 1.5%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.createdate" })}</p>
              <br />
              {item.created_at_gmt}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              fontSize: "12px",
              fontfamily: "SFProText-Bold,SFProText",
              color: "rgba(33,43,54,1)",
              ineHeight: "14px",
              marginBottom: "10px",
              padding: "20px 0 10px",
              background: "rgba(245,245,245,1)",
            }}
          >
            <div
              style={{
                width: "100%",
                margin: "0 0 20px 3%",
                fontSize: "18px",
                fontFamily: "SFProText-Bold SFProText",
                fontWeight: "bold",
                color: "rgba(33,43,54,1)",
                lineHeight: "20px",
              }}
            >
              <p  className="title" style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.tracking" })}</p>
            </div>

            <div style={{ width: "30%", margin: "0px 0px 10px 3%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.trackingnumber" })}</p>
              <br />
              <a
                href={`https://t.17track.net/en#nums=${item.tracking_number}`}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  color: "#006fbb",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {item.tracking_number}
              </a>
            </div>
            <div style={{ width: "30%", margin: "0px 0px 10px 1.5%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.trackingcompany" })}</p>
              <br />
              {item.tracking_company}
            </div>
            <div style={{ width: "30%", margin: "0px 0px 10px 1.5%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.trackingupdatedate" })}</p>
              <br />
              {item.tracking_date_gmt}
            </div>
            <div style={{ width: "30%", margin: "0px 0px 10px 3%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.trackingstatus" })}</p>
              <br />
              {item.tracking_status}
            </div>
            <div style={{ width: "30%", margin: "0px 0px 10px 1.5%" }}>
              <p style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.fulfillmentstatus" })}</p>
              <br />
              {item.fulfillment_status}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              fontSize: "12px",
              fontfamily: "SFProText-Bold,SFProText",
              color: "rgba(33,43,54,1)",
              ineHeight: "14px",
              padding: "20px 0 10px",
              background: "rgba(245,245,245,1)",
              marginBottom: "-10px",
            }}
          >
            <div
              style={{
                width: "100%",
                margin: "0 0 20px 3%",
                fontSize: "18px",
                fontFamily: "SFProText-Bold SFProText",
                fontWeight: "bold",
                color: "rgba(33,43,54,1)",
                lineHeight: "20px",
              }}
            >
              <p  className="title" style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "msg.lineitems" })}</p>
            </div>
            {lineItem}
          </div>
        </Modal.Section>
      </Modal>
    </div>
  );
}
export default connect((state) => {
  return {
    ...state.order,
    shopUrl:state.initial.shopUrl,
    loading: state.loading.models.order,
    datas: state.order.datas,
    token: state.initial.token,
  };
})(injectIntl(ResourceListFilters));
