import React from "react";
import { injectIntl } from 'react-intl';

class ListHead extends React.Component {
  render() {
    const {intl}=this.props
    const style = {
      width: "100%",
      display: "inline-flex",
      justifyContent: "space-around",
      alignItems: "center",
      fontSize: "14px",
      wordWrap: "break-word",
      fontFamily: "SFProText-Regular,SFProText",
      fontWeight: "400",
      padding: "12px 20px 12px 60px",
    };
    return (
      <div style={{ borderBottom: "1px solid #dfe3e8" }}>
        <div style={style}>
          <div style={{ width: "15%", marginRight: "1.5%",textAlign: "center" }}>{intl.formatMessage({id:"list.checkout"})}</div>
          <div style={{ width: "15%", marginRight: "0.5%",textAlign: "center" }}>{intl.formatMessage({id:"list.creationdate"})}</div>
          <div style={{ width: "18%", marginRight: "0.5%",textAlign: "center" }}>{intl.formatMessage({id:"list.emails"})}</div>
          <div style={{ width: "18%", marginRight: "0.5%",textAlign: "center" }}>
          {intl.formatMessage({id:"list.deliverydate"})}
          </div>
          <div style={{ width: "9%", marginRight: "0.5%",textAlign: "center" }}>
          {intl.formatMessage({id:"list.total"})}
          </div>
          <div style={{ width: "9%", marginRight: "0.5%",textAlign: "center" }}>{intl.formatMessage({id:"list.quantity"})}</div>
          <div style={{ width: "14%", marginRight: "0.5%",textAlign: "center" }}>
          {intl.formatMessage({id:"list.emailstatus"})}
          </div>
          <div style={{ width: "14%", marginRight: "0.5%",textAlign: "center" }}>
          {intl.formatMessage({id:"list.recoverystatus"})}
          </div>
          <div style={{ width: "10%", marginRight: "0.5%",textAlign: "center" }}>{intl.formatMessage({id:"list.action"})}</div>
        </div>
      </div>
    );
  }
}
export default injectIntl(ListHead);
