import qs from "qs";
import request from "../utils/request";

export async function query(params) {
  const config = {
    method: "get",
    url: "/api/orders",
    params,
  };
  return request(config);
}

export async function addBatchTrackings(data) {
  const config = {
    method: "post",
    url: "/api/orders/batch_tracking",
    data,
  };
  return request(config);
}

export async function queryAbandoned(params) {
  const config = {
    method: "get",
    url: "/api/abandoned_checkouts",
    params,
  };
  return request(config);
}

export async function generateInvoice(data) {
  const config = {
    method: "post",
    url: "/api/abandoned_checkouts/generate_invoice",
    data,
  };
  return request(config);
}

export async function queryDashboard() {
  const config = {
    method: "get",
    url: "/api/dashboard",
  };
  return request(config);
}
export async function getToken(data) {
  const config = {
    method: "post",
    url: "/api/oauth/token",
    data: qs.parse(data),
  };
  return request(config);
}
export async function getSet() {
  const config = {
    method: "get",
    url: "/api/user/settings",
  };
  return request(config);
}
export async function postSet(data) {
  const config = {
    method: "post",
    url: "/api/user/settings",
    data,
  };
  return request(config);
}