import React from "react";
import { AppProvider, Layout } from "@shopify/polaris";
import { injectIntl } from "react-intl";
import { connect } from "dva";
import { colorGreenDark } from "@shopify/polaris-tokens";

class AuthorizationSuccess extends React.Component {
  state = {
    visible: 5,
  };
  async componentDidMount() {
    const { getLanguage } = this.props;
    const lang = window.location.search.replace("?lang=", "");
    getLanguage(lang, "en");
  }
  render() {
    const { intl } = this.props;
    var i;
    if (this.state.visible === 5) {
      i = setInterval(() => {
        this.setState({ visible: this.state.visible - 1 });
        if (this.state.visible < 1) {
          clearInterval(i);
          window.close();
        }
      }, 1000);
    }

    return (
      <AppProvider>
        <div style={{ background: "rgba(255,255,255,1)", height: "530px" }}>
          <Layout>
            <Layout.Section>
              <img
                src="./Social_media_marketing.png"
                alt="show more"
                width="72%"
                height="63%"
                style={{
                  display: "block",
                  margin: "0 auto",
                  marginTop: "37px",
                }}
              />
              <div
                style={{
                  width: " 100%",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: "19px",
                  fontFamily: "SFProText-Bold,SFProText",
                  lineHeight: "24px",
                  color: "rgba(67,80,94,1)",
                }}
              >
                {intl.formatMessage({ id: "authorizationSuccess.successful" })}
              </div>
              <div
                style={{
                  width: " 100%",
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "10px",
                  color: "rgba(153,164,175,1)",
                  fontFamily: "SFProText-Regular,SFProText",
                  height: "16px",
                  lineHeight: "16px",
                }}
              >
                {`${intl.formatMessage({
                  id: "authorizationSuccess.closethispage",
                })} ${this.state.visible} ${intl.formatMessage({
                  id: "authorizationSuccess.seconds",
                })}`}
              </div>
              <div style={{ textAlign: "center", marginTop: "29px" }}>
                <button
                  onClick={() => window.close()}
                  style={{
                    width: "140px",
                    height: "36px",
                    background: colorGreenDark,
                    boxShadow:
                      "0px 1px 0px 0px rgba(22,29,37,0.1),0px 1px 0px 1px rgba(255,255,255,0.06)",
                    borderRadius: "3px",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    color: "rgba(255,255,255,1)",
                    fontFamily: "SFProText-Regular,SFProText",
                  }}
                >
                  {intl.formatMessage({
                    id: "authorizationSuccess.closethepage",
                  })}
                </button>
              </div>
            </Layout.Section>
          </Layout>
        </div>
      </AppProvider>
    );
  }
}
export default connect((state) => {
  return {
    ...state.initial,
    language: state.set.language,
  };
})(injectIntl(AuthorizationSuccess));
