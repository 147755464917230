import React from 'react'
import '@shopify/polaris/styles.css'
import { Chart,Legend, Line, Point,Axis } from 'bizcharts';

class LineChart extends React.Component {
  state = {};
  render() {
    const { data,type,color } = this.props;
    const scale = {
      count: {
        min: 0,
        minTickInterval:1,
        tickInterval: 1,
      },
    };
    return (
      <>
        <Chart scale={scale} padding={[30, 40, 50, 40]} autoFit height={400} data={data} >
          <Axis name="count" />
          <Legend position='top-right' />
          <Line shape="smooth" position={type} color={color}/>
          <Point position={type} color="tags" />
        </Chart>
      </>
    )
  }
}

export default LineChart;