import { getSet, postSet } from "../services/api";

export default {
    namespace: "set",

    state: {
        language: "",
        // 储存表单勾选与选择
        choiceListBool: "",
        choiceList: "",
        // 与初始值做对比判断是否可以保存
        choiceListBool2: "",
        choiceList2: "",
    },

    effects: {
        *getLanguage({ payload }, { put, call }) {
            const res = yield call(getSet)
            yield put({ type: "loadLanguage", payload: res });
        },
        *setLanguage({ payload }, { put, call }) {
            yield call(postSet, { "lang": payload })
            yield put({ type: "saveLanguage", payload });
        },
        // 请求settings初始值
        *getSet(_, { call, put }) {
            const data = yield call(getSet);
            let set={}
            if(data.retarget_setting.value){
                set = {
                    retargeting_interval: data.retargeting_interval.value,
                    retargeting_switch: data.retargeting_switch.value ? 1 : 0,
                };
            }else{
                set = {
                    retargeting_interval: 10,
                    retargeting_switch: 1,
                };
            }
            yield put({ type: "saveSet", set });
            return data;
        },
        // settings是否勾选
        *postChoiceListBool({ payload }, { call, put }) {
            if (payload.length === 0) {
                payload = "0";
            }
            if (payload !== "0") {
                payload = payload[1];
            }
            const set = { retargeting_switch: payload };
            yield put({ type: "saveSet", set });
        },
        // settings选择时间
        *postChoiceList({ payload }, { call, put }) {
            const set = { retargeting_interval: payload[0] };
            yield put({ type: "saveSet", set });
        },
        *postSet({ payload }, { call, put, select }) {
            const { set } = yield select();
            let data,status;
            set.choiceListBool === "0"?status=false:status=true
            data = {
                retargeting_switch: status,
                retargeting_interval: parseInt(set.choiceList),
                invoice_logo_url:payload.url||null,
                invoice_term:payload.term||null,
                invoice_note:payload.note||null,
                shop_name:payload.businessName||null,
                retarget_setting:true
            };
            const res = yield call(postSet, data);
            return res
        },
    },

    reducers: {
        loadLanguage(state, action) {
            return {
                ...state,
                language: action.payload.lang.value
            }
        },
        saveLanguage(state, action) {
            return {
                ...state,
                language: action.payload,
            }
        },
        // 保存setting到modal
        saveSet(state, action) {
            if (
                action.set.retargeting_switch !== undefined &&
                action.set.retargeting_interval !== undefined
            ) {
                return {
                    ...state,
                    choiceListBool: String(action.set.retargeting_switch),
                    choiceListBool2: String(action.set.retargeting_switch),
                    choiceList: String(action.set.retargeting_interval),
                    choiceList2: String(action.set.retargeting_interval),
                };
            }
            if (
                action.set.retargeting_switch === undefined &&
                action.set.retargeting_interval !== undefined
            ) {
                return {
                    ...state,
                    choiceList: String(action.set.retargeting_interval),
                };
            }
            if (
                action.set.retargeting_switch !== undefined &&
                action.set.retargeting_interval === undefined
            ) {
                return {
                    ...state,
                    choiceListBool: String(action.set.retargeting_switch),
                };
            }
        },
    },
};