import {queryAbandoned,generateInvoice}from "../services/api";

//isEqual：判断两个对象是否键值对应相等
function isEqual(a, b) {
  //如果a和b本来就全等
  if (a === b) {
    //判断是否为0和-0
    return a !== 0 || 1 / a === 1 / b;
  }
  //判断是否为null和undefined
  if (a == null || b == null) {
    return a === b;
  }
  //接下来判断a和b的数据类型
  var classNameA = toString.call(a),
    classNameB = toString.call(b);
  //如果数据类型不相等，则返回false
  if (classNameA !== classNameB) {
    return false;
  }
  //如果数据类型相等，再根据不同数据类型分别判断
  switch (classNameA) {
    case "[object RegExp]":
    case "[object String]":
      //进行字符串转换比较
      return "" + a === "" + b;
    case "[object Number]":
      //判断是否为0或-0
      return +a === 0 ? 1 / +a === 1 / b : +a === +b;
    case "[object Date]":
    case "[object Boolean]":
      return +a === +b;
    default:
  }
  //如果是对象类型
  if (classNameA === "[object Object]") {
    //获取a和b的属性长度
    var propsA = Object.getOwnPropertyNames(a),
      propsB = Object.getOwnPropertyNames(b);
    if (propsA.length !== propsB.length) {
      return false;
    }
    for (var i = 0; i < propsA.length; i++) {
      var propName = propsA[i];
      //如果对应属性对应值不相等，则返回false
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }
  //如果是数组类型
  if (classNameA === "[object Array]") {
    if (a.toString() === b.toString()) {
      return true;
    }
    return false;
  }
}
export default {
  namespace: "retargeting",

  state: {
    data: [],
    page: 1,
    datas: {},
    fils: [],
  },

  effects: {
    //请求数据
    *query({ payload }, { call, put, select }) {
      const { retargeting } = yield select();
      if (!isEqual(retargeting.datas, payload)) {
        var response = yield call(queryAbandoned, payload);
        yield put({ type: "save", response, payload });
      }
    },
    //数据异步刷新
    *asynRefresh({ _ }, { call, put, select }) {
      const { retargeting } = yield select();
      var payload = retargeting.datas;
      var response = yield call(queryAbandoned, payload);
      yield put({ type: "save", response });
    },
    //过滤
    *fil({ payload }, { _, put, select }) {
      const { retargeting } = yield select();
      if (retargeting.fils !== payload) {
        yield put({ type: "saveFil", payload });
      }
    },
    //添加批记录
    *addBatchTrackings({ payload }, { call, put }) {
      const response = yield call(generateInvoice, payload);
      yield put({ type: "save", response });
    },
  },

  reducers: {
    save(state, action) {
      const bool = action.payload === undefined;
      if (bool) {
        return { ...state, ...action.response };
      }
      if (!bool) {
        return {
          ...state,
          ...action.response,
          page: action.payload.page,
          datas: action.payload,
        };
      }
    },
    saveFil(state, action) {
      return { ...state, fils: action.payload };
    },
    error(state) {
      const s = JSON.parse(JSON.stringify(state));
      delete s.message;
      delete s.statusCode;
      delete s.banStatus;
      delete s.lastUrl;
      return s;
    },
  },
};
