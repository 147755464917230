import React from "react";
import { injectIntl } from 'react-intl';

class ListHead extends React.Component {
  render() {
    const {intl}=this.props
    const style = {
      width: "100%",
      display: "inline-flex",
      justifyContent: "space-around",
      alignItems: "center",
      fontSize: "14px",
      wordWrap: "break-word",
      fontFamily: "SFProText-Regular,SFProText",
      fontWeight: "400",
      padding: "12px 20px 12px 60px",
    };
    return (
      <div style={{ borderBottom: "1px solid #dfe3e8" }}>
        <div style={style}>
          <div style={{ width: "3.6%", marginRight: "0.5%" }}>{intl.formatMessage({id:"list.num"})}</div>
          <div style={{ width: "4%", marginRight: "0.5%" }}>{intl.formatMessage({id:"list.order"})}</div>
          <div style={{ width: "15%", marginRight: "0.5%" }}>
          {intl.formatMessage({id:"list.transactionid"})}
          </div>
          <div style={{ width: "18%", marginRight: "0.5%" }}>
          {intl.formatMessage({id:"list.trackingnumber"})}
          </div>
          <div style={{ width: "14%", marginRight: "0.5%" }}>{intl.formatMessage({id:"list.email"})}</div>
          <div style={{ width: "9.5%", marginRight: "0.5%" }}>{intl.formatMessage({id:"list.createdate"})}</div>
          <div style={{ width: "9%", marginRight: "0.5%" }}>
          {intl.formatMessage({id:"list.trackingupdatedate"})}
          </div>
          <div style={{ width: "10.5%", marginRight: "0.5%" }}>
          {intl.formatMessage({id:"list.trackingstatus"})}
          </div>
          <div style={{ width: "11.4%", marginRight: "0.5%" }}>{intl.formatMessage({id:"list.syncstatus"})}</div>
        </div>
      </div>
    );
  }
}
export default injectIntl(ListHead);
