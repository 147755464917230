import React from 'react'
import '@shopify/polaris/styles.css'
import {
  AppProvider,
  Card,
  Layout,
  Tabs,
  Page,
  Banner
} from "@shopify/polaris";
import { withRouter } from 'dva/router';
import Header from '../../components/Header/index'
import { connect } from "dva";
import { injectIntl } from 'react-intl';
import ListFilters from "./listFilters";
import en from "@shopify/polaris/locales/en.json";
import zh from "@shopify/polaris/locales/zh-CN.json";
import {colorGreenDark} from '@shopify/polaris-tokens';

class Orders extends React.Component {
  state = {
    selected: 1,
    message: undefined,
    banStatus: undefined,
  };
  async componentDidUpdate() {
    this.props.getLanguage(this.props.language, this.props.lang)
    const { message, lastUrl, banStatus } = this.props;
    // 如果按下Send Tracking按钮就重新请求订单
    if (
      lastUrl === "/api/orders/batch_tracking" &&
      banStatus !== this.state.banStatus
    ) {
      this.setState({ message, banStatus });
      this.props.dispatch({
        type: "order/asynRefresh",
      });
      if (banStatus === "success") {
        const remove = () => {
          this.props.dispatch({
            type: "order/error",
          });
          this.setState({ message: undefined, banStatus: undefined });
        };
        setTimeout(remove, 10000);
      }
    }
  }
  // 控制tabs切换
  handleTabChange = (selected) => {
    const { history, hamc } = this.props
    this.setState({ selected });
    switch (selected) {
      case 0:
        history.push(`/?${hamc}`)
        break;
      case 2:
        history.push(`/Retargeting?${hamc}`)
        break;
      default:
        break;
    }
  };

  render() {
    const { selected, } = this.state;
    const {
      intl,
      message,
      banStatus,
    } = this.props;

    let messages = {}
    messages['en'] = en;
    messages['zh'] = zh;

    let tabs = [
      {
        id: "dashboard",
        content: intl.formatMessage({ id: "list.dashboard" }),
      },
      {
        id: "all-orders",
        content: intl.formatMessage({ id: "list.all" }),
      },
      // {
      //   id: "retargeting",
      //   content: intl.formatMessage({ id: "list.retargeting" }),
      // },
    ];
    //使用rgb情况下shopfiy颜色系统颜色显示异常，故替换为rgba
    const theme={
      colors:{
          interactive: colorGreenDark&&colorGreenDark.replace("rgb","rgba"),
      }
    }

    return (
      <AppProvider features={{newDesignLanguage: true}} theme={theme} i18n={this.props.language ? messages[this.props.language] : messages[this.props.lang]}>
        <Header>
          {message ? (
            <Banner
              title={message === "sendOrder" ? intl.formatMessage({ id: `cue.sendOrderSuccess` }) : message}
              status={banStatus}
              onDismiss={() => {
                this.props.dispatch({
                  type: "order/error",
                });
              }}
            />
          ) : (
              []
            )}
        </Header>
        <div style={{ marginTop: "-50px" }}>
          <Page fullWidth>
            <Layout>
              <Layout.Section>
                <Card>
                  <Tabs
                    tabs={tabs}
                    selected={selected}
                    onSelect={this.handleTabChange}
                  >
                    <ListFilters />
                  </Tabs>
                </Card>
              </Layout.Section>
            </Layout>
          </Page>
        </div>
      </AppProvider>
    )
  }
}

export default connect((state) => {
  return {
    ...state.order,
    ...state.initial,
    language: state.set.language
  }
})(injectIntl(withRouter(Orders)));