import dva from 'dva'
import { createBrowserHistory as createHistory } from 'history'
import createLoading from 'dva-loading'
import "./style/global.scss"
// 1. Initialize
// const app = dva()
const app = dva({
  history: createHistory({ basename: '/app' }),
})

// 2. Plugins
app.use(createLoading())

// 3. Model
app.model(require('./models/initial').default)
app.model(require('./models/orders').default)
app.model(require('./models/set').default)
app.model(require('./models/retargeting').default)
app.model(require('./models/dashboard').default)

// 4. Router
app.router(require('./router').default)

// 5. Start
app.start('#root')

// dispatch 对象在app._store里面
export default app._store
