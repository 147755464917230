import { queryDashboard } from "../services/api";

export default {
    namespace: "dashboard",

    state: {
        email: [],
        order: [],
        retargeting: [],
    },

    effects: {
        *queryDashboard(_, { put, call }) {
            const res = yield call(queryDashboard)
            if (res.order) {
                yield put({ type: "saveDashboard", payload: res });
            }
        },
    },

    reducers: {
        saveDashboard(state, action) {
            return {
                ...state,
                email: action.payload.email,
                order: action.payload.order,
                retargeting: action.payload.retargeting,
            }
        },
    },
};