import React from "react";
import { ResourceItem, Icon } from "@shopify/polaris";
import { connect } from "dva";
import {
  RiskMinor
} from '@shopify/polaris-icons';
import { injectIntl } from 'react-intl';
import monotone from '../../assets/images/Monotone.png';

class ListItem extends React.Component {
  // 改变字体颜色
  fontColor = (syncState) => {
    const style = {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "space-around",
      wordWrap: "break-word",
      fontSize: "14px",
      fontFamily: "SFProText-Regular,SFProText",
      fontWeight: "400",
    };
    switch (syncState) {
      // 未同步黑色
      case "not_sync":
        return { ...style, color: "#000000" };
      // 同步且信息一致绿色
      case "sync":
        return { ...style, color: "#108043" };
      // 同步但信息不一致黄色
      case "sync_diff":
        return { ...style, color: "#ad8b00" };
      // 默认黑色
      default:
        return { ...style };
    }
  };
  // 是焦点时加下划线
  isFocus = (event) => {
    switch (event.type) {
      case "mouseenter":
        event.target.style.textDecoration = "";
        break;
      case "mouseleave":
        event.target.style.textDecoration = "none";
        break;
      default:
        break;
    }
  };
  // 时间格式
  formatConversion = (date) => {
    const re1 = /(?<year>[0-9]{4})[-](?<month>[0-9]{2})[-](?<day>[0-9]{2})/;
    const r1 = re1.exec(date);
    if (r1) {
      date = new Date(
        r1.groups.year,
        r1.groups.month - 1,
        r1.groups.day
      ).toString();
    }
    const re2 = /([a-zA-Z]+)[ ](?<month>[a-zA-Z]+)[ ](?<day>[0-9]{2})[ ](?<year>[0-9]{4})/;
    const r2 = re2.exec(date);
    if (r2) {
      date = `${r2.groups.month} ${r2.groups.day},${r2.groups.year}`;
    }
    return date;
  };

  orderNum = (id) => {
    const { data, page } = this.props;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        return page * 10 + i + 1 - 10;
      }
    }
  };

  render() {
    const { item, handleModalChange, shopUrl, intl } = this.props;
    let {
      id,
      name,
      transaction_id,
      tracking_number,
      email,
      created_at_gmt,
      tracking_date_gmt,
      tracking_status,
      paypal_sync_status,
      paypal_sync_status_text,
    } = item;
    created_at_gmt = this.formatConversion(created_at_gmt);
    tracking_date_gmt = this.formatConversion(tracking_date_gmt);

    const dateStatus = (data) => {
      let dateArr = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec']
      let arr = data.split(' ').reverse()
      let popNum = arr.pop()
      let carr = arr[0].split(',')
      let tmp = dateArr.indexOf(popNum)
      return `${tmp + 1}月${carr[0]}日(${carr[1]})`
    }

    const syncStatus = (data) => {
      switch (data) {
        case "NOT SYNC":
          return intl.formatMessage({ id: "item.nosync" })
        case "SYNCED":
          return intl.formatMessage({ id: "item.synced" })
        case "DIFFERENT":
          return intl.formatMessage({ id: "item.different" })
        default:
          return intl.formatMessage({ id: "item.nosync" })
      }
    }
    const trackingStatus = (data) => {
      switch (data) {
        case "SHIPPED":
          return intl.formatMessage({ id: "item.shipped" })
        case "ON HOLD":
          return intl.formatMessage({ id: "item.onhold" })
        case "DELIVERED":
          return intl.formatMessage({ id: "item.delivered" })
        case "CANCELLED":
          return intl.formatMessage({ id: "item.cancelled" })
        default:
          return " "
      }
    }

    return (
      <div>
        <ResourceItem
          id={id}
          verticalAlignment="center"
          accessibilityLabel={`View details for ${id}`}
        >
          <div style={this.fontColor(paypal_sync_status&&paypal_sync_status.toLowerCase())}>
            <div
              style={{
                width: "2.6%",
                marginRight: "1.5%",
                textAlign: "center",
              }}
            >
              {this.orderNum(id)}
            </div>
            <div style={{ width: "4%", marginRight: "0.5%" }}>
              <a
                href={`https://${shopUrl}/admin/orders/${id}`}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  color: "#006fbb",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {name}
              </a>
            </div>
            <div style={{ width: "15%", marginRight: "0.5%" }}>
              {transaction_id}
            </div>
            <div style={{ width: "18%", marginRight: "0.5%" }}>
              {tracking_number}
            </div>
            <div style={{ width: "14%", marginRight: "0.5%" }}>{email}</div>
            <div style={{ width: "9.5%", marginRight: "0.5%" }}>
              {intl.formatMessage({ id: "list.createdate" }) === "Create Date" ? created_at_gmt : dateStatus(created_at_gmt)}
            </div>
            <div style={{ width: "9%", marginRight: "0.5%" }}>
              {intl.formatMessage({ id: "list.createdate" }) === "Create Date" ? tracking_date_gmt : dateStatus(tracking_date_gmt)}
            </div>
            <div style={{ width: "10.5%", marginRight: "0.5%" }}>
              {trackingStatus(tracking_status)}
            </div>
            <div style={{ width: "9.4%", marginRight: "0.5%", display: "flex" }}>
              {syncStatus(paypal_sync_status_text)}
              {paypal_sync_status_text === "DIFFERENT" ?
                <Icon
                  source={RiskMinor}
                  color="red"
                  width="20px"
                  fontSize="20px"
                />
                : null}
            </div>
            <div
              style={{ width: "2%" }}
              onClick={() => handleModalChange(item)}
            >
              <img
                src={monotone}
                alt="show more"
                width="20px"
                height="20px"
                style={{ display: "block", margin: "0 auto" }}
              />
            </div>
          </div>
        </ResourceItem>
      </div>
    );
  }
}
export default connect((state) => {
  return {
    ...state.order,
    shopUrl: state.initial.shopUrl
  };
})(injectIntl(ListItem));
