const en_US = {
  'page.title': "Sync Tracking For PayPal",
  // 绑定PayPal按钮
  'update.btn': "Update Permission For Tracking",
  'update.title': "Tracking API Permission",
  'update.confirm': "Confirm",
  'update.close': "Close",
  'update.card': "Give Tracking API Permission to your PayPal Account",
  'update.text': "Make sure you have given the permission to the PayPal account being used in your Shopify store",
  'remind.btn': "Update Permission For Tracking",
  'remind.text': "Make sure you give permission with the PayPal account being used in your Shopify store",
  'authentication.title': "Please enter app from Shopify",
  'settingMsg.text':"Settings successfully saved",

  //主页筛选器
  'list.all': "Orders",
  'list.notyetshipped': "Not yet shipped",
  'list.retargeting': "Retargeting",
  'list.dashboard': "Dashboard",
  'list.trackinginfo': "tracking info",
  'list.abandonedinfo': "abandoned checkout info",
  'list.settings': "Settings",
  'syncStatus.title': "Sync Status",
  'syncStatus.nosync': "NOT SYNCED",
  'syncStatus.synced': "SYNCED",
  'syncStatus.different': "DIFFERENT",
  'syncStatus.clear': "clear",
  'trackingStatus.title': "Tracking Status",
  'trackingStatus.shipped': "SHIPPED",
  'trackingStatus.onhold': "ON HOLD",
  'trackingStatus.delivered': "DELIVERED",
  'trackingStatus.cancelled': "CANCELLED",
  'trackingStatus.clear': "clear",
  'fulfillmentStatus.title': "Fulfillment Status",
  'fulfillmentStatus.fulfilled': "FULFILLED",
  'fulfillmentStatus.unfulfille': "UNFULFILLED",
  'fulfillmentStatus.partial': "PARTIAL",
  'fulfillmentStatus.restocked': "RESTOCKED",
  'email.delivered': "Delivered",
  'email.undelivered': "Undelivered",
  'recovered.recovered': "Recovered",
  'recovered.unrecovered': "Unrecovered",
  'startDate.title': "Start Date",
  'endDate.title': "End Date",
  'emailStatus.title': "Email Status",
  'emailStatus.sent': "Delivered",
  'emailStatus.unsent': "Undelivered",
  'recoeredStatus.title': "Recovered Status",
  'recoeredStatus.recoered': "Recovered",
  'recoeredStatus.unrecoered': "Unrecovered",
  'sentNumbers.title': "Quantity",
  'deliveryDate.title': "Delivery Date",
  'creationDate.title': "Creation Date",

  'ruleText.textHeader': "Store abandonment orders will only be synchronized to our app if ",
  'ruleText.textBody': "the following conditions",
  'ruleText.textFooter': " are met",
  'ruleText.textOne': "1.The abandoned order must contain the user’s Email information;",
  'ruleText.textTwo': "2.The abandoned order must be a settlement order with the shipping address filled in;",
  // 'ruleText.textTwo': "2.The abandoned order must be with PayPal in the logged-in status;",
  // 'ruleText.textThree': "3.The abandoned order must be a settlement order with the shipping address filled in;",
  'ruleText.title': "Store abandonment orders will only be synchronized to our app if the following conditions are met",
  'ruleText.close': "close",

  //订单列表
  'list.num': "Num",
  'list.order': "Order",
  'list.transactionid': "Transaction ID",
  'list.trackingnumber': "Tracking Number",
  'list.email': "Email",
  'list.createdate': "Create Date",
  'list.trackingupdatedate': "Tracking Update Date",
  'list.trackingstatus': "Tracking Status",
  'list.syncstatus': "Sync Status",
  'list.send': "Send Tracking",
  'list.sendab': "Send Invoice Email",
  'list.checkout': "Checkout",
  'list.creationdate': "Creation Date",
  'list.emails': "Email",
  'list.deliverydate': "Delivery Date",
  'list.total': "Total",
  'list.quantity': "Quantity",
  'list.emailstatus': "Email Status",
  'list.recoverystatus': "Recovery Status",
  'list.action': "Action",

  //标签信息
  'tags.syncstatus': "Sync Status",
  'tags.trackingstatus': "Tracking Status",
  'tags.fulfillmentstatus': "Fulfillment Status",
  'tags.shipmentstatus': "Shipment Status",
  'tags.starting': "Starting",
  'tags.Ending': "Ending",
  'tags.emailstatus': "Email Status",
  'tags.recoveredstatus': "Recovered Status",
  'tags.sentnumbers': "Delivered Quantity",
  'tags.delivered': "Delivered",
  'tags.undelivered': "Undelivered",
  'tags.creationdate': "CreationDate",
  'tags.deliverydate': "DeliveryDate",


  //排序信息
  'sort.datedesc': "Date Desc",
  'sort.dateasc': "Date Asc",
  'sort.trackingcompanydesc': "Tracking Company Desc",
  'sort.trackingcompanyasc': "Tracking Company Asc",
  'sort.email': "Email",
  'sort.dateDesc': "Creation Date Desc",
  'sort.sendDateDesc': "Delivery Date Desc",
  'sort.dateAsc': "Creation Date Asc",
  'sort.sendDateAsc': "Delivery Date Asc",

  // 设置
  'set.title': "Settings",
  'set.save': "Save",
  'set.cancel': "Cancel",
  'set.retargeting': "Retargeting",
  'set.autom': "Automatically send PayPal invoices",
  'set.sendafter': "Send After",
  'set.hours': "hours",
  'set.minutes': "minutes",
  'set.recommended': "（recommended）",
  'set.businessName': "Business Name:",
  'set.logoUrl': "Logo Url:",
  'set.term': "Term:",
  'set.note': "Note:",
  'set.text': "The image pixel must be less than 250 * 90" ,
  'set.hint': "Supports up to 1000 characters",
  'set.error':"The invoice logo url format is invalid",
  'set.errorName':"The shop name must be a string",
  'set.billcover':"Invoice title:",
  'set.bill':"Invoice details:",
  'set.eg':"e.g.",
  'set.noticeTitle':"Retargeting function officially launched",
  'set.noticeText1':"1. For abandoned orders paid by PayPal, the buyer will receive a receipt email from PayPal within the specified time",
  'set.noticeText2':"2. In the receipt mail, the merchant can customize the mail name, company LOGO, etc. ",
  'set.noticeText3':"3. Merchants can choose the email sending interval, we recommend 10 minutes.",
  'set.noticeText4':"4. Orders paid by bill will appear in the order list. ",

  //订单详情页
  'msg.title': "The order details",
  'msg.close': "Close",
  'msg.order': "Order ",
  'msg.orderid': "Order ID : ",
  'msg.paypaltrans': "PayPal Trans ID : ",
  'msg.email': "Email",
  'msg.amount': "Amount : ",
  'msg.createdate': "Create Date : ",
  'msg.tracking': "Tracking ",
  'msg.trackingnumber': "Tracking Number :",
  'msg.trackingcompany': "Tracking Company : ",
  'msg.trackingupdatedate': "Tracking Update Date",
  'msg.trackingstatus': "Tracking Status",
  'msg.fulfillmentstatus': "Fulfillment Status : ",
  'msg.lineitems': "Line Items ",

  //单条订单
  'item.nosync': "NOT SYNCED",
  'item.synced': "SYNCED",
  'item.different': "DIFFERENT",
  'item.shipped': "SHIPPED",
  'item.onhold': "ON HOLD",
  'item.delivered': "DELIVERED",
  'item.cancelled': "CANCELLED",
  'item.sent': "Delivered",
  'item.scheduled': 'Scheduled',
  'item.unsent': "Undelivered",
  'item.recoered': "Recovered",
  'item.unrecoered': "Unrecovered",
  'item.sentbtn': "Send",

  'chart.fail': "Failed Orders",
  'chart.paypal': "PayPal Orders",
  'chart.synced': "Synced Orders",
  'chart.success': "Confirmed Orders",
  'chart.email': "Emails",
  'chart.retargeting': "Order Recovery",
  'chart.title': "Tracking Analytics",
  'chart.titletwo': "Abandoned Checkout Analytics",
  'chart.titles': "Tracking Analytics",
  'chart.titlef': "Abandoned Checkout Analytics",
  'chart.ordersbypaypal': "PayPal Orders",
  'chart.syncorders': "Synced Orders",
  'chart.succcessfulorders': "Confirmed Orders",
  'chart.failedorders': "Failed Orders",
  'chart.sentemail': "Email Engagement",
  'chart.successfulsentemail': "Delivered Emails",
  'chart.recoveryorders': "Order Recovery",
  'chart.recoveredsclles': "Sales Recovery",

  'authorizationSuccess.successful': "PayPal authorization successful",
  'authorizationSuccess.closethispage': "Close this page after",
  'authorizationSuccess.seconds': "seconds",
  'authorizationSuccess.closethepage': "Close the page",

  'cue.sendOrderSuccess': 'The trackings has been successfully synchronized to PayPal. ',
  'cue.sendEmailSuccess': 'Sending invoice email',
}

export default en_US;