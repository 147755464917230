import React from "react";
import {
  Banner,
  Card,
  Page,
  Layout,
  Modal,
  TextStyle,
  Button,
  ChoiceList,
  Select,
  TextField,
  Icon,
} from "@shopify/polaris";
import { injectIntl } from "react-intl";
import { connect } from "dva";
// import Setting from "../../assets/images/Setting.png";
import Bill from "../../assets/images/bill.jpg";
import Billcover from "../../assets/images/billCover.jpg";

class App extends React.Component {
  state = {
    active: false,
    selected: 0,
    screenWidth: "",
    setActive: false,
    choiceListBool: "1",
    choiceList: "8",
    message: undefined,
    banStatus: undefined,
    authorityKey: true,
    authentication: false,
    settingMsg: false,
    text: {},
    oldText: {},
    errorText: "",
    previewStatus: {},
    retargetSetting: true,
  };

  async componentDidMount() {
    if (!this.props.token) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "726753ac-5b67-4ed7-87f0-b26af68db12f";
      (function () {
        var d = document;
        var s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }
    if (!window.location.search.includes("hmac")) {
      this.setState({
        authentication: true,
      });
    }
    this.setState({
      screenWidth: document.body.clientWidth,
    });
  }

  // 控制Modal显示状态
  handleModalChange = () => {
    const { active } = this.state;
    this.setState({ active: !active });
  };

  handleModalChangeSetActive = async (e) => {
    const { setActive } = this.state;
    const { dispatch } = this.props;
    this.setState({
      setActive: !setActive,
      errorText: "",
      settingMsg: false,
    });
    //判断是否为弹出modal窗操作
    if (e === "1") {
      let data = await dispatch({
        type: "set/getSet",
      });
      let value = {
        businessName: data.shop_name.value || null,
        url: data.invoice_logo_url.value || null,
        term: data.invoice_term.value || null,
        note: data.invoice_note.value || null,
      };
      this.setState({
        oldText: value,
        text: JSON.parse(JSON.stringify(value)),
        retargetSetting: data.retarget_setting && data.retarget_setting.value,
      });
    } else {
      this.setState({
        oldText: {},
        text: {},
      });
    }
  };
  // 更变勾选框的值
  ChoiceListBoolChange = (value) => {
    this.props.dispatch({
      type: "set/postChoiceListBool",
      payload: value,
    });
  };
  // 更变setting的值
  ChoiceListChange = (value) => {
    this.props.dispatch({
      type: "set/postChoiceList",
      payload: value,
    });
  };
  //保存setting
  handleModalChangeSaveSetting = async () => {
    const { text, setActive } = this.state;
    const { intl } = this.props;
    let data = {};
    Object.keys(text).forEach((element) => {
      if (text[element]) {
        data[element] = text[element].trim();
      }
    });
    const res = await this.props.dispatch({
      type: "set/postSet",
      payload: data,
    });
    if (res.message) {
      this.setState({
        errorText: intl.formatMessage({ id: "set.error" }),
        oldText: {
          ...text,
          url: "",
        },
      });
    } else {
      this.setState({
        setActive: !setActive,
        oldText: {},
        settingMsg: true,
      });
      setTimeout(() => {
        this.setState({
          settingMsg: false,
        });
      }, 5000);
    }
  };

  handleTextChange = (value, tag) => {
    const { text, errorText } = this.state;
    let cost = text;
    cost[tag] = value || null;
    errorText !== "" &&
      this.setState({
        errorText: "",
      });
    this.setState({
      text: { ...cost },
    });
  };
  // Confirm按钮导航至PayPal
  Confirm = (e) => {
    if (!e) {
      this.handleModalChange();
    }
    let top =
      window.screen.availHeight - (document.body.clientHeight + 650) / 2;
    let left = window.screen.availWidth - (document.body.clientWidth + 440) / 2;
    let authorityState = window.open(
      `/paypal/authenticate?token=${this.props.token}`,
      "newwindow",
      `height=500px, width=450px, top=${top}, left=${left}, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no`
    );
    let that = this;
    let loop = setInterval(async () => {
      if (authorityState && authorityState.closed && authorityState.location) {
        clearInterval(loop);
        const payload_ = window.location.search.replace("?", "");
        if (that.state.authorityKey) {
          await that.setState({
            authorityKey: false,
          });
          await that.props.dispatch({
            type: "initial/getToken",
            payload: payload_,
          });
          that.setState({
            authorityKey: true,
          });
        }
      }
    }, 1000);
  };

  handlePreview = (status, type) => {
    this.setState({
      previewStatus: {
        status: status,
        type: type,
      },
    });
  };

  render() {
    const {
      active,
      setActive,
      screenWidth,
      authentication,
      text,
      errorText,
      oldText,
      previewStatus,
      settingMsg,
      retargetSetting,
    } = this.state;
    const {
      choiceListBool,
      choiceList,
      choiceListBool2,
      choiceList2,
      authorized,
      intl,
      language,
      lang,
      langItem,
    } = this.props;
    const handleSelectChange = async (value) => {
      const { dispatch } = this.props;
      await dispatch({
        type: "set/setLanguage",
        payload: value,
      });
      if (this.props.handlelang) {
        this.props.handlelang();
      }
    };

    const icon = (type) => (
      <div
        style={{
          verticalAlign: "middle",
          position: "relative",
          top: "50%",
          left: "10%",
          transform: "translateY(-50%)",
          margin: "0 5px",
          cursor: "pointer",
          fontSize: "8px",
        }}
        onClick={() => {
          this.handlePreview(true, type);
        }}
      >
        <div
          style={{
            width: "18px",
            margin: "0 auto",
            position: "relative",
            top: "2px",
          }}
        >
          <Icon
            color="skyDark"
            source='<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 1a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1zm0 4a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zM8.083 4A6.002 6.002 0 0014 9h2v9.5a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 18.5v-13A1.5 1.5 0 011.5 4h6.583zM5 11a2 2 0 100-4 2 2 0 000 4zm-2.574 7h11.145c.325 0 .531-.328.377-.6l-3.462-5.609a.446.446 0 00-.72-.016L7.143 15.6l-1.397-1.48a.449.449 0 00-.617.007l-3.045 3.241c-.206.264-.01.632.342.632z"/></svg>'
          />
        </div>
        <div style={{ position: "relative", top: "-2px" }}>
          {intl.formatMessage({ id: "set.eg" })}
        </div>
      </div>
    );

    return (
      <div>
        <Page title={intl.formatMessage({ id: "page.title" })} fullWidth>
          <div
            style={{
              position: screenWidth > 970 ? "absolute" : "initial",
              top: "32px",
              right: "32px",
              zIndex: "1",
              display: "flex",
            }}
          >
            <div style={{ display: "flex", marginRight: "10px" }}>
              <Select
                options={langItem}
                onChange={handleSelectChange}
                value={language ? language : lang}
              />
            </div>
            <div style={{ display: "flex", marginRight: "10px" }}>
              <Button primary onClick={() => this.handleModalChange()}>
                {intl.formatMessage({ id: "update.btn" })}
              </Button>
            </div>
            {/* <div style={{ display: "flex" }}>
              <Button
                primary
                onClick={() => this.handleModalChangeSetActive("1")}
                icon={<img src={Setting} alt="" width="20px" height="20px" />}
              >
                {intl.formatMessage({ id: "list.settings" })}
              </Button>
            </div> */}
          </div>
          <Layout>
            <Layout.Section>
              {settingMsg ? (
                <Banner
                  title={intl.formatMessage({ id: "settingMsg.text" })}
                  status="success"
                  onDismiss={() => {
                    this.setState({
                      settingMsg: false,
                    });
                  }}
                />
              ) : (
                []
              )}
              {!authorized ? (
                <Banner
                  title={intl.formatMessage({ id: "remind.text" })}
                  action={{
                    content: intl.formatMessage({ id: "remind.btn" }),
                    onClick: () => this.Confirm(1),
                  }}
                  status="warning"
                />
              ) : (
                []
              )}
              {authentication ? (
                <Banner
                  title={intl.formatMessage({ id: "authentication.title" })}
                  status="warning"
                  onDismiss={() => {
                    this.setState({
                      authentication: false,
                    });
                  }}
                />
              ) : (
                []
              )}
              {this.props.children}
            </Layout.Section>
          </Layout>
          <Modal
            open={active}
            onClose={this.handleModalChange}
            title={intl.formatMessage({ id: "update.title" })}
            limitHeight
            primaryAction={{
              content: intl.formatMessage({ id: "update.confirm" }),
              onAction: () => this.Confirm(0),
            }}
            secondaryActions={[
              {
                content: intl.formatMessage({ id: "update.close" }),
                onAction: this.handleModalChange,
              },
            ]}
          >
            <Modal.Section>
              <Card title={intl.formatMessage({ id: "update.card" })} sectioned>
                <TextStyle variation="negative">
                  {intl.formatMessage({ id: "update.text" })}
                </TextStyle>
              </Card>
            </Modal.Section>
          </Modal>
          <Modal
            open={setActive}
            onClose={this.handleModalChangeSetActive}
            title={intl.formatMessage({ id: "set.title" })}
            primaryAction={{
              content: intl.formatMessage({ id: "set.save" }),
              onAction: this.handleModalChangeSaveSetting,
              disabled:
                retargetSetting &&
                choiceListBool === choiceListBool2 &&
                choiceList === choiceList2 &&
                (!text.businessName ||
                  JSON.stringify(text) === JSON.stringify(oldText)),
            }}
            secondaryActions={[
              {
                content: intl.formatMessage({ id: "set.cancel" }),
                onAction: this.handleModalChangeSetActive,
              },
            ]}
          >
            <Modal.Section>
              <div style={{ paddingLeft: "12px" }}>
                <div
                  style={{
                    fontSize: "14px",
                    fontFamily: "SFProText-Bold,SFProText",
                    fontWeight: "bold",
                    color: "rgba(33,43,54,1)",
                    lineHeight: "20px",
                    paddingBottom: "4px",
                  }}
                >
                  {intl.formatMessage({ id: "set.retargeting" })}
                </div>
                <ChoiceList
                  allowMultiple
                  choices={[
                    {
                      label: intl.formatMessage({ id: "set.autom" }),
                      value: "1",
                    },
                  ]}
                  selected={choiceListBool === "0" ? [""] : [choiceListBool]}
                  onChange={(value) => this.ChoiceListBoolChange(value)}
                />
                <div style={{ paddingTop: "16px", display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "14px",
                      fontFamily: "SFProText-Regular,SFProText",
                      fontWeight: "400",
                      color: "rgba(33,43,54,1)",
                      lineHeight: "20px",
                    }}
                  >
                    {" "}
                    {intl.formatMessage({ id: "set.sendafter" })}
                    :&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <div style={{ display: "flex" }}>
                    <ChoiceList
                      choices={[
                        {
                          label: `10 ${intl.formatMessage({
                            id: "set.minutes",
                          })}${intl.formatMessage({ id: "set.recommended" })}`,
                          value: "10",
                        },
                        {
                          label: `3 ${intl.formatMessage({ id: "set.hours" })}`,
                          value: "180",
                        },
                        {
                          label: `8 ${intl.formatMessage({ id: "set.hours" })}`,
                          value: "480",
                        },
                        {
                          label: `24 ${intl.formatMessage({
                            id: "set.hours",
                          })}`,
                          value: "1440",
                        },
                        {
                          label: `48 ${intl.formatMessage({
                            id: "set.hours",
                          })}`,
                          value: "2880",
                        },
                      ]}
                      selected={[choiceList]}
                      onChange={(value) => this.ChoiceListChange(value)}
                      disabled={choiceListBool === "0"}
                    />
                  </div>
                </div>
                <br />
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "14%",
                      paddingRight: "13px",
                      textAlign: "right",
                    }}
                  >
                    <span
                      style={
                        intl.formatMessage({ id: "set.businessName" }) !==
                        "Business Name:"
                          ? { lineHeight: "36px" }
                          : null
                      }
                    >
                      {intl.formatMessage({ id: "set.businessName" })}
                    </span>
                  </div>
                  <div style={{ width: "86%" }}>
                    <TextField
                      connectedRight={icon("businessName")}
                      value={text.businessName}
                      maxLength={70}
                      placeholder={intl.formatMessage({ id: "set.errorName" })}
                      type="text"
                      onChange={(value) =>
                        this.handleTextChange(value, "businessName")
                      }
                    />
                  </div>
                </div>
                <br />
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "14%",
                      padding: "5px 13px 0 0",
                      textAlign: "right",
                    }}
                  >
                    <span>{intl.formatMessage({ id: "set.logoUrl" })}</span>
                  </div>
                  <div style={{ width: "86%" }}>
                    <TextField
                      connectedRight={icon("url")}
                      maxLength={1000}
                      value={text.url}
                      error={errorText || false}
                      spellCheck={true}
                      type="url"
                      multiline={true}
                      required
                      placeholder={intl.formatMessage({ id: "set.text" })}
                      onChange={(value) => this.handleTextChange(value, "url")}
                    />
                  </div>
                </div>
                <br />
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "14%",
                      padding: "5px 13px 0 0",
                      textAlign: "right",
                    }}
                  >
                    <span>{intl.formatMessage({ id: "set.term" })}</span>
                  </div>
                  <div style={{ width: "86%" }}>
                    <TextField
                      connectedRight={icon("term")}
                      value={text.term}
                      maxLength={1000}
                      type="text"
                      multiline={true}
                      placeholder={intl.formatMessage({ id: "set.hint" })}
                      onChange={(value) => this.handleTextChange(value, "term")}
                    />
                  </div>
                </div>
                <br />
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "14%",
                      padding: "5px 13px 0 0",
                      textAlign: "right",
                    }}
                  >
                    <span>{intl.formatMessage({ id: "set.note" })}</span>
                  </div>
                  <div style={{ width: "86%" }}>
                    <TextField
                      connectedRight={icon("note")}
                      value={text.note}
                      multiline={true}
                      placeholder={intl.formatMessage({ id: "set.hint" })}
                      maxLength={1000}
                      onChange={(value) => this.handleTextChange(value, "note")}
                    />
                  </div>
                </div>
              </div>
            </Modal.Section>
          </Modal>
          <Modal
            open={previewStatus.status}
            large
            title=" "
            onClose={() => this.handlePreview(false)}
            primaryAction={{
              content: intl.formatMessage({ id: `msg.close` }),
              onAction: () => this.handlePreview(false),
            }}
          >
            <Modal.Section>
              <div
                style={{
                  width: "920px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {previewStatus.type !== "note" ? (
                  <Card title={intl.formatMessage({ id: `set.billcover` })}>
                    <img
                      style={{ width: "700px", color: "red" }}
                      src={Billcover}
                      alt="Billcover"
                    />
                  </Card>
                ) : null}
                <Card title={intl.formatMessage({ id: `set.bill` })}>
                  <img style={{ width: "960px" }} src={Bill} alt="Bill" />
                </Card>
              </div>
            </Modal.Section>
          </Modal>
        </Page>
      </div>
    );
  }
}
export default connect((state) => {
  return {
    ...state.initial,
    ...state.set,
    abandonedlastUrl: state.retargeting.lastUrl,
    datas: state.retargeting.datas,
  };
})(injectIntl(App));
