// 初始化token
let token = localStorage.getItem('token')

// 获取token
export function getToken() {
  return token
}

// 设置token
export function setToken(t) {
  localStorage.setItem('token', `Bearer ${t}`)
  token = `Bearer ${t}`
}
